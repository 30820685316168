import { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig, roles, FORM_STATUS } from "../../../configs/admin-config";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  Button,
  InputBase,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NewResellerDialog from "../Dialog/NewResellerDialog";
import { getAccessToken, checkIfRoleExists } from "../../../utils/oauth";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #AAA",
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.light,
  },
  accordionHeading: {
    fontSize: 14,
    fontWeight: 500,
  },
  accordionDetails: {
    display: "block",
  },
  searchGrid: {
    marginBottom: 20,
  },
  filterGrid: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  filterLabel: {
    fontSize: 14,
  },
  checkBox: {
    fontSize: 9,
    textAlign: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tableContainer: {
    marginTop: 20,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
  },
  column: {
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      // color: "white",
      fontWeight: 600,
    },
  },
  tableRow1: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  tableRow2: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 11,
  },
  addButton: {
    textTransform: "none",
  },
  dialog: {
    border: "1px solid black",
  },
  dialogActions: {
    paddingRight: 23,
    paddingTop: 15,
    paddingBottom: 20,
  },
  msgTypo: {
    paddingTop: 20,
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
  infoMsg: {
    marginTop: 10,
    color: "grey",
  },
  errorMsg: {
    color: "red",
    fontSize: 12,
    marginBottom: 5,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Resellers = ({ id }) => {
  let query = useQuery();
  const classes = useStyles();
  const history = useHistory();

  const [resellers, setResellers] = useState([]);
  const [filteredResellers, setFilteredResellers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snack, setSnackBar] = useState({ visibility: false, severity: "", message: "" });
  const [backdropOpen, setbackDrop] = useState(true);
  const [formRender, setFormRender] = useState(0);
  const [sortColumn, setSortColumn] = useState("Company Name");
  const [sortPriority, setSortPriority] = useState("DESC");
  const [priorityChanged, setPriorityChanged] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isInactive, setInactive] = useState(false);

  const [formStatusFilters, setFormStatusFilters] = useState({
    signed: true,
    completed: true,
    drafted: true,
    pending: true,
    requestedToEdit: true,
  });
  const [financeApprovalFilters, setFinanceApprovalFilters] = useState({
    pending: true,
    approved: true,
    rejected: true,
  });
  const [legalApprovalFilters, setLegalApprovalFilters] = useState({ approved: true, rejected: true, pending: true });

  const getResellers = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          let resellers = [];
          let links = [];
          if (body.partnerInfo.length > 0) {
            resellers = body.partnerInfo;
          }
          if (body.partnerLinkInfo.length > 0) {
            links = body.partnerLinkInfo;
          }
          let resellerLinks = [];

          links.map((link) => {
            const linkId = link.linkId;
            const reseller =
              resellers.filter((res) => res.linkId === linkId).length > 0
                ? resellers.filter((res) => res.linkId === linkId)[0]
                : {
                    companyId: "",
                    linkId: linkId,
                    companyName: link.companyName,
                    taxIdNumber: "-",
                    streetAddress: "-",
                    state: "-",
                    cityProvince: "-",
                    postalZipCode: "-",
                    country: "-",
                    yearOfIncorporation: "-",
                    noOfEmployees: "-",
                    noOfCustomers: "-",
                    websiteLink: "-",
                    formStatus: "active",
                    financeResult: "pending",
                    legalResult: "pending",
                    region: link.region,
                  };

            if (reseller) {
              link["reseller"] = reseller;
            }

            resellerLinks.push(link);
          });
          setResellers(resellerLinks);

          if (query.get("id")) {
            setFilteredResellers(
              sortRows(
                resellerLinks.filter((item) => item.linkId === query.get("id")),
                sortColumn
              )
            );
          } else {
            setFilteredResellers(
              sortRows(
                resellerLinks.filter((item) => item.status === "active"),
                sortColumn
              )
            );
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        setSnackBar({ visibility: true, severity: "error", message: "Something went wrong while retrieving data" });
      }

      setbackDrop(false);
      return null;
    } catch (error) {
      setSnackBar({ visibility: true, severity: "error", message: "Something went wrong while retrieving data" });
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    setbackDrop(true);
    getResellers();
  }, [formRender]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredResellers.length - page * rowsPerPage);

  const openSummary = (item) => {
    if (
      item.reseller.formStatus === FORM_STATUS.COMPLETED ||
      item.reseller.formStatus === FORM_STATUS.EDIT_COMPLETED ||
      item.reseller.formStatus === FORM_STATUS.SIGNED ||
      item.reseller.formStatus === FORM_STATUS.DRAFTED ||
      item.reseller.formStatus === FORM_STATUS.TR_DRAFTED ||
      item.reseller.formStatus === FORM_STATUS.PENDING ||
      item.reseller.formStatus === FORM_STATUS.TR_EDIT_REQUESTED ||
      item.reseller.formStatus === FORM_STATUS.FULL_EDIT_REQUESTED
    ) {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.RESELLER_DASHBOARD + item.reseller.companyId + "/profile",
        state: { tabName: "profile", encodeString: item.encodeString },
      });
    } else {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.PENDING_PAGE + item.reseller.linkId,
        state: { tabName: "profile", item: item },
      });
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    // let filteredSearch = resellers.filter(item=>(
    //     (item.reseller.companyName ? item.reseller.companyName.toLowerCase().includes(input.toLowerCase()):
    //         item.temp_company_name.toLowerCase().includes(input.toLowerCase())) ||
    //     item.email.toLowerCase().includes(input.toLowerCase()) ||
    //     (item.reseller.country.toLowerCase().includes(input.toLowerCase()))
    // ))
    // setSearchedResellers(filteredSearch)
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleCheckbox = (e) => {
    if (!e.target.checked) {
      setFormStatusFilters({ signed: true, completed: true, drafted: true, pending: true, requestedToEdit: true });
      setFinanceApprovalFilters({ pending: true, approved: true, rejected: true });
      setLegalApprovalFilters({ approved: true, rejected: true, pending: true });
    }

    setInactive(e.target.checked);
    if (e.target.checked) {
      setFilteredResellers(resellers.filter((item) => item.status === "inactive"));
    } else {
      setFilteredResellers(resellers.filter((item) => item.status === "active"));
    }
  };

  const handleFormStatusFilters = (e) => {
    if (
      (e.target.name === "drafted" || e.target.name === "pending" || e.target.name === "requestedToEdit") &&
      !formStatusFilters.completed
    ) {
      setLegalApprovalFilters({ approved: true, rejected: true, pending: true });
      setFinanceApprovalFilters({ approved: true, rejected: true, pending: true });
    }
    setFormStatusFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleFinanceApprovalFilter = (e) => {
    setFinanceApprovalFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleLegalApprovalHandler = (e) => {
    setLegalApprovalFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const sortRows = (array, sortColumn) => {
    if (sortPriority === "DESC") {
      if (sortColumn === "Company Name") {
        return array.sort((a, b) =>
          a.reseller.companyName.toLowerCase() > b.reseller.companyName.toLowerCase()
            ? 1
            : b.reseller.companyName.toLowerCase() > a.reseller.companyName.toLowerCase()
            ? -1
            : 0
        );
      } else if (sortColumn === "Contact Email") {
        return array.sort((a, b) =>
          a.email.toLowerCase() > b.email.toLowerCase() ? 1 : b.email.toLowerCase() > a.email.toLowerCase() ? -1 : 0
        );
      } else if (sortColumn === "Country") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.country.toLowerCase()
            ? 1
            : b.reseller.country.toLowerCase() > a.reseller.country.toLowerCase()
            ? -1
            : 0
        );
      } else if (sortColumn === "Form Status") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.formStatus.toLowerCase()
            ? 1
            : b.reseller.formStatus.toLowerCase() > a.reseller.formStatus.toLowerCase()
            ? -1
            : 0
        );
      } else if (sortColumn === "Finance Approval") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.financeResult.toLowerCase()
            ? 1
            : b.reseller.financeResult.toLowerCase() > a.reseller.financeResult.toLowerCase()
            ? -1
            : 0
        );
      } else if (sortColumn === "Legal Approval") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.legalResult.toLowerCase()
            ? 1
            : b.reseller.legalResult.toLowerCase() > a.reseller.legalResult.toLowerCase()
            ? -1
            : 0
        );
      } else {
        return array;
      }
    } else {
      if (sortColumn === "Company Name") {
        return array.sort((a, b) =>
          a.reseller.companyName.toLowerCase() > b.reseller.companyName.toLowerCase()
            ? -1
            : b.reseller.companyName.toLowerCase() > a.reseller.companyName.toLowerCase()
            ? 1
            : 0
        );
      } else if (sortColumn === "Contact Email") {
        return array.sort((a, b) =>
          a.email.toLowerCase() > b.email.toLowerCase() ? -1 : b.email.toLowerCase() > a.email.toLowerCase() ? 1 : 0
        );
      } else if (sortColumn === "Country") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.country.toLowerCase()
            ? -1
            : b.reseller.country.toLowerCase() > a.reseller.country.toLowerCase()
            ? 1
            : 0
        );
      }
      // else if(sortColumn==="Region"){
      //     return (array.sort((a,b) => (a.reseller.region.toLowerCase() > b.reseller.region.toLowerCase() ) ?
      //         -1 : ((b.reseller.region.toLowerCase()  > a.reseller.region.toLowerCase() ) ? 1 : 0)))
      // }
      else if (sortColumn === "Form Status") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.formStatus.toLowerCase()
            ? -1
            : b.reseller.formStatus.toLowerCase() > a.reseller.formStatus.toLowerCase()
            ? 1
            : 0
        );
      } else if (sortColumn === "Finance Approval") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.financeResult.toLowerCase()
            ? -1
            : b.reseller.financeResult.toLowerCase() > a.reseller.financeResult.toLowerCase()
            ? 1
            : 0
        );
      } else if (sortColumn === "Legal Approval") {
        return array.sort((a, b) =>
          a.reseller.country.toLowerCase() > b.reseller.legalResult.toLowerCase()
            ? -1
            : b.reseller.legalResult.toLowerCase() > a.reseller.legalResult.toLowerCase()
            ? 1
            : 0
        );
      } else {
        return array;
      }
    }
  };

  const setSortColumnValue = (columnName) => {
    setPriorityChanged(true);
    setSortColumn(columnName);
    setFilteredResellers(sortRows(filteredResellers, columnName));
    if (sortPriority === "ASC") {
      setSortPriority("DESC");
    } else {
      setSortPriority("ASC");
    }
  };

  useEffect(() => {
    setSortPriority("ASC");
    setFilteredResellers(sortRows(filteredResellers, sortColumn));
  }, [sortColumn]);

  useEffect(() => {
    if (resellers.length > 0) {
      var filteredResellerList = [];

      // Filtering using Form Status
      if (formStatusFilters.signed) {
        filteredResellerList = resellers.filter(
          (res) => res.reseller.formStatus === "signed" && res.status === "active"
        );
      }

      if (formStatusFilters.completed) {
        filteredResellerList = resellers.filter(
          (res) =>
            (res.reseller.formStatus === FORM_STATUS.COMPLETED ||
              res.reseller.formStatus === FORM_STATUS.EDIT_COMPLETED) &&
            res.status === "active"
        );
      }

      if (formStatusFilters.drafted) {
        filteredResellerList = resellers
          .filter((res) => res.reseller.formStatus === "drafted" && res.status === "active")
          .concat(filteredResellerList);
      }

      if (formStatusFilters.pending) {
        filteredResellerList = resellers
          .filter((res) => res.reseller.formStatus === "active" && res.status === "active")
          .concat(filteredResellerList);
      }

      if (formStatusFilters.requestedToEdit) {
        filteredResellerList = resellers
          .filter(
            (res) =>
              (res.reseller.formStatus === "tr_edit_requested" || res.reseller.formStatus === "full_edit_requested") &&
              res.status === "active"
          )
          .concat(filteredResellerList);
      }
      setFilteredResellers(sortRows(filteredResellerList, sortColumn));

      // Filtering using Finance Approval
      var tempList = filteredResellerList;
      var filteredResellerList2 = [];

      if (financeApprovalFilters.approved) {
        filteredResellerList2 = tempList.filter(
          (res) => res.reseller.financeResult === "approved" && res.status === "active"
        );
      }

      if (financeApprovalFilters.rejected) {
        filteredResellerList2 = tempList
          .filter(
            (res) =>
              res.reseller.financeResult === "rejected" &&
              res.status === "active" &&
              !filteredResellerList2.includes(res)
          )
          .concat(filteredResellerList2);
      }

      if (financeApprovalFilters.pending) {
        filteredResellerList2 = tempList
          .filter(
            (res) =>
              res.reseller.financeResult === "pending" &&
              res.status === "active" &&
              !filteredResellerList2.includes(res)
          )
          .concat(filteredResellerList2);
      }

      setFilteredResellers(sortRows(filteredResellerList2, sortColumn));

      // Filtering using Legal Approval
      tempList = filteredResellerList2;
      var filteredResellerList3 = [];

      if (legalApprovalFilters.approved) {
        filteredResellerList3 = tempList.filter(
          (res) => res.reseller.legalResult === "approved" && res.status === "active"
        );
      }

      if (legalApprovalFilters.rejected) {
        filteredResellerList3 = tempList
          .filter(
            (res) =>
              res.reseller.legalResult === "rejected" && res.status === "active" && !filteredResellerList3.includes(res)
          )
          .concat(filteredResellerList3);
      }

      if (legalApprovalFilters.pending) {
        filteredResellerList3 = tempList
          .filter(
            (res) =>
              res.reseller.legalResult === "pending" && res.status === "active" && !filteredResellerList3.includes(res)
          )
          .concat(filteredResellerList3);
      }

      setFilteredResellers(sortRows(filteredResellerList3, sortColumn));
    }
  }, [formStatusFilters, financeApprovalFilters, legalApprovalFilters]);

  const renderIndicator = (columnName) => {
    if (sortColumn === columnName && priorityChanged) {
      if (sortPriority === "ASC") {
        return <span style={{ fontSize: 20 }}>&#8595;</span>;
      } else {
        return <span style={{ fontSize: 20 }}>&#8593;</span>;
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ visibility: false, severity: "", message: "" });
  };

  return (
    <div>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={snack.visibility} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={1} alignItems="flex-end" className={classes.searchGrid}>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          {(checkIfRoleExists(roles.admin) || checkIfRoleExists(roles.channelManager)) && (
            <Button
              className={classes.addButton}
              variant="contained"
              color="secondary"
              onClick={openDialog}
              fullWidth
              startIcon={<AddCircleOutlineIcon />}
            >
              Request Due Diligence
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={5} className={classes.checkBox}>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                className={classes.checkBox}
                size="small"
                checked={isInactive}
                onChange={handleCheckbox}
                name="isInactive"
                color="Secondary"
              />
            }
            label={<span style={{ fontSize: 13 }}>Show Inactive Companies</span>}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={8} lg={5}>
          <Paper component="form" className={classes.root}>
            <InputBase className={classes.input} placeholder="Search" onChange={handleSearch} />
            <SearchIcon />
          </Paper>
        </Grid>
      </Grid>

      {!isInactive && (
        <Accordion>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.accordionHeading}>Search Filters</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography>
              {!isInactive && (
                <Grid container spacing={1} className={classes.filterGrid}>
                  <Grid item lg={2} sm={3} xs={12}>
                    <Typography className={classes.filterLabel}>Form Status :</Typography>
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="signed"
                          checked={formStatusFilters.signed}
                          onChange={handleFormStatusFilters}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Completed</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="completed"
                          checked={formStatusFilters.completed}
                          onChange={handleFormStatusFilters}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Pending Signature</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="drafted"
                          checked={formStatusFilters.drafted}
                          onChange={handleFormStatusFilters}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Drafted</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="pending"
                          checked={formStatusFilters.pending}
                          onChange={handleFormStatusFilters}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Pending</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="requestedToEdit"
                          checked={formStatusFilters.requestedToEdit}
                          onChange={handleFormStatusFilters}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Requested to Edit</span>}
                    />
                  </Grid>
                </Grid>
              )}

              {!isInactive && (formStatusFilters.signed || formStatusFilters.requestedToEdit) && (
                <Grid container spacing={1} className={classes.filterGrid}>
                  <Grid item lg={2} sm={3} xs={12}>
                    <Typography className={classes.filterLabel}>Finance Approval :</Typography>
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="approved"
                          checked={financeApprovalFilters.approved}
                          onChange={handleFinanceApprovalFilter}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Approved</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="rejected"
                          checked={financeApprovalFilters.rejected}
                          onChange={handleFinanceApprovalFilter}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Rejected</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="pending"
                          checked={financeApprovalFilters.pending}
                          onChange={handleFinanceApprovalFilter}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Pending</span>}
                    />
                  </Grid>
                </Grid>
              )}

              {!isInactive && (formStatusFilters.signed || formStatusFilters.requestedToEdit) && (
                <Grid container spacing={1} className={classes.filterGrid}>
                  <Grid item lg={2} sm={3} xs={12}>
                    <Typography className={classes.filterLabel}>Legal Approval :</Typography>
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="approved"
                          checked={legalApprovalFilters.approved}
                          onChange={handleLegalApprovalHandler}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Approved</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="rejected"
                          checked={legalApprovalFilters.rejected}
                          onChange={handleLegalApprovalHandler}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Rejected</span>}
                    />
                  </Grid>
                  <Grid item lg={1} md={2}>
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          className={classes.checkBox}
                          size="small"
                          name="pending"
                          checked={legalApprovalFilters.pending}
                          onChange={handleLegalApprovalHandler}
                          color="Secondary"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Pending</span>}
                    />
                  </Grid>
                </Grid>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}

      {!backdropOpen && (
        <Fragment>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Company Name")}>
                    Company Name {renderIndicator("Company Name")}
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Contact Email")}>
                    Contact Email {renderIndicator("Contact Email")}
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Country")}>
                    Country {renderIndicator("Country")}
                  </TableCell>

                  <TableCell align="center">Form Status </TableCell>
                  <TableCell align="center">Finance Approval </TableCell>
                  <TableCell align="center">Legal Approval </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResellers.length > 0 &&
                  filteredResellers
                    .filter(
                      (item) =>
                        (item.reseller.companyName
                          ? item.reseller.companyName.toLowerCase().includes(searchInput.toLowerCase())
                          : item.companyName.toLowerCase().includes(searchInput.toLowerCase())) ||
                        item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
                        item.reseller.country.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index} onClick={() => openSummary(item)} className={classes.tableRow1}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell>{item.reseller.companyName || item.companyName}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.reseller.country}</TableCell>
                        {/* <TableCell >{item.reseller.region}</TableCell> */}
                        <TableCell align="center">
                          <Typography
                            className={
                              item.reseller.formStatus === "drafted" ||
                              item.reseller.formStatus === "tr_edit_requested" ||
                              item.reseller.formStatus === "full_edit_requested"
                                ? classes.secondaryText
                                : classes.primaryText
                            }
                          >
                            {item.reseller.formStatus === FORM_STATUS.SIGNED
                              ? "completed"
                              : item.reseller.formStatus === FORM_STATUS.COMPLETED ||
                                item.reseller.formStatus === FORM_STATUS.EDIT_COMPLETED
                              ? "pending signature"
                              : item.reseller.formStatus === FORM_STATUS.TR_EDIT_REQUESTED ||
                                item.reseller.formStatus === FORM_STATUS.FULL_EDIT_REQUESTED
                              ? "requested to edit"
                              : item.reseller.formStatus === FORM_STATUS.DRAFTED ||
                                item.reseller.formStatus === FORM_STATUS.TR_DRAFTED
                              ? "drafted"
                              : "pending"}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.column2} align="center">
                          <Typography className={classes.primaryText}>{item.reseller.financeResult}</Typography>
                        </TableCell>
                        <TableCell className={classes.column2} align="center">
                          <Typography className={classes.primaryText}>{item.reseller.legalResult}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredResellers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}

      {!backdropOpen &&
        filteredResellers.filter(
          (item) =>
            (item.reseller.companyName
              ? item.reseller.companyName.toLowerCase().includes(searchInput.toLowerCase())
              : item.companyName.toLowerCase().includes(searchInput.toLowerCase())) ||
            item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.reseller.country.toLowerCase().includes(searchInput.toLowerCase())
        ).length === 0 && (
          <Typography className={classes.msgTypo} variant="body2">
            No Resellers Found
          </Typography>
        )}

      {dialogOpen && (
        <NewResellerDialog
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          setSnackBar={setSnackBar}
          setFormRender={setFormRender}
        />
      )}
    </div>
  );
};

export default Resellers;
