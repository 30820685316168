import { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig } from "../../../configs/admin-config";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  InputBase,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getAccessToken } from "../../../utils/oauth";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #AAA",
  },
  searchGrid: {
    marginBottom: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.light,
  },
  accordionHeading: {
    fontSize: 14,
    fontWeight: 500,
  },
  accordionDetails: {
    display: "block",
  },
  filterGrid: {
    display: "flex",
    alignItems: "center",
  },
  filterLabel: {
    fontSize: 14,
  },
  table: {
    minWidth: 650,
  },
  column: {
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      // color: "white",
      fontWeight: 600,
    },
  },
  tableContainer: {
    marginTop: 20,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
  },
  tableRow1: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  tableRow2: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  statusChip: {
    fontWeight: "bold",
    fontSize: 11,
  },
  button: {
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 11,
  },
  msgTypo: {
    paddingTop: 20,
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TradeReferences = ({ id }) => {
  let query = useQuery();

  const classes = useStyles();
  const history = useHistory();

  const [tradeReferences, setTradeReferences] = useState([]);
  const [filteredTradeReferences, setFilteredTradeReferences] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [successSnack, setSuccess] = useState(false);
  const [sortColumn, setSortColumn] = useState("Partner Company");
  const [sortPriority, setSortPriority] = useState("DESC");
  const [priorityChanged, setPriorityChanged] = useState(false);
  const [copySnack, setCopy] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [backdropOpen, setbackDrop] = useState(true);
  const [formStatusFilters, setFormStatusFilters] = useState({
    completed: true,
    drafted: true,
    pending: true,
    // requestedToEdit: true,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getTradeReferences = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.getTradeReferences;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          let refs = [];
          let links = [];
          let resellers = [];
          if (body.tradeReferences.length > 0) {
            refs = body.tradeReferences;
          }

          if (body.tradeReferenceLinks.length > 0) {
            links = body.tradeReferenceLinks;
          }

          if (body.resellers.length > 0) {
            resellers = body.resellers;
          }

          let tradeRefLinks = [];
          links.map((link) => {
            const linkId = link.linkId;
            const companyName =
              refs.filter((ref) => ref.linkId === linkId && ref.subQuestionId === 27).length > 0
                ? refs.filter((ref) => ref.linkId === linkId && ref.subQuestionId === 27)[0].answer
                : null;
            const resellerCompanyName =
              resellers.filter((res) => res.companyId === link.companyId).length > 0
                ? resellers.filter((res) => res.companyId === link.companyId)[0].companyName
                : "-";
            link["companyName"] = companyName || link.companyName;
            link["resellerCompanyName"] = resellerCompanyName;
            tradeRefLinks.push(link);
          });
          setTradeReferences(tradeRefLinks);
          if (query.get("id")) {
            setFilteredTradeReferences(
              sortRows(
                tradeRefLinks.filter((item) => item.linkId === query.get("id")),
                "Partner Company"
              )
            );
          } else {
            setFilteredTradeReferences(sortRows(tradeRefLinks, "Partner Company"));
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }

      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    setbackDrop(true);
    getTradeReferences();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredTradeReferences.length - page * rowsPerPage);

  const openTradeRef = (tradeRef) => {
    if (tradeRef.status === "completed" || tradeRef.status === "drafted" || tradeRef.status === "pending") {
      history.push({
        pathname:
          AppConfig.urls.ADMIN + AppConfig.urls.TRADE_REFERENCE_FORM + tradeRef.companyId + "/" + tradeRef.linkId,
        state: { encodeString: tradeRef.encodeString },
      });
    } else {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.PENDING_PAGE_2 + tradeRef.linkId,
        state: { item: tradeRef, encodeString: tradeRef.encodeString },
      });
    }
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const handleFormStatusFilters = (e) => {
    setFormStatusFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setCopy(false);
  };

  const sortRows = (array, sortColumn) => {
    if (sortPriority === "DESC") {
      if (sortColumn === "Partner Company") {
        return array.sort((a, b) =>
          a.resellerCompanyName > b.resellerCompanyName ? 1 : b.resellerCompanyName > a.resellerCompanyName ? -1 : 0
        );
      } else if (sortColumn === "Reference Company") {
        return array.sort((a, b) => (a.companyName > b.companyName ? 1 : b.companyName > a.companyName ? -1 : 0));
      } else if (sortColumn === "Contact Email") {
        return array.sort((a, b) => (a.email > b.email ? 1 : b.email > a.email ? -1 : 0));
      } else if (sortColumn === "Form Status") {
        return array.sort((a, b) => (a.status > b.status ? 1 : b.status > a.status ? -1 : 0));
      } else {
        return array;
      }
    } else {
      if (sortColumn === "Partner Company") {
        return array.sort((a, b) =>
          a.resellerCompanyName > b.resellerCompanyName ? -1 : b.resellerCompanyName > a.resellerCompanyName ? 1 : 0
        );
      } else if (sortColumn === "Reference Company") {
        return array.sort((a, b) => (a.companyName > b.companyName ? -1 : b.companyName > a.companyName ? 1 : 0));
      } else if (sortColumn === "Contact Email") {
        return array.sort((a, b) => (a.email > b.email ? -1 : b.email > a.email ? 1 : 0));
      } else if (sortColumn === "Form Status") {
        return array.sort((a, b) => (a.status > b.status ? -1 : b.status > a.status ? 1 : 0));
      } else {
        return array;
      }
    }
  };

  const setSortColumnValue = (columnName) => {
    setPriorityChanged(true);
    setSortColumn(columnName);
    setFilteredTradeReferences(sortRows(filteredTradeReferences, columnName));
    if (sortPriority === "ASC") {
      setSortPriority("DESC");
    } else {
      setSortPriority("ASC");
    }
  };

  useEffect(() => {
    setSortPriority("ASC");
    setFilteredTradeReferences(sortRows(filteredTradeReferences, sortColumn));
  }, [sortColumn]);

  useEffect(() => {
    if (tradeReferences.length > 0) {
      var filteredResellerList = [];

      if (formStatusFilters.completed) {
        filteredResellerList = tradeReferences.filter((res) => res.status === "completed");
      }

      if (formStatusFilters.drafted) {
        filteredResellerList = tradeReferences.filter((res) => res.status === "drafted").concat(filteredResellerList);
      }

      if (formStatusFilters.pending) {
        filteredResellerList = tradeReferences.filter((res) => res.status === "active").concat(filteredResellerList);
      }

      // if (formStatusFilters.requestedToEdit) {
      //   filteredResellerList = tradeReferences.filter((res) => res.status === "pending").concat(filteredResellerList);
      // }
      setFilteredTradeReferences(filteredResellerList);
    }
  }, [formStatusFilters]);

  const renderIndicator = (columnName) => {
    if (sortColumn === columnName && priorityChanged) {
      if (sortPriority === "ASC") {
        return <span style={{ fontSize: 20 }}>&#8595;</span>;
      } else {
        return <span style={{ fontSize: 20 }}>&#8593;</span>;
      }
    }
  };

  return (
    <div>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={copySnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Copied to Clipboard!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Grid container spacing={1} alignItems="flex-end" className={classes.searchGrid}>
        <Grid item xs={12} sm={12} md={12} lg={8}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper component="form" className={classes.root}>
            <InputBase className={classes.input} placeholder="Search" onChange={handleSearch} />
            <SearchIcon />
          </Paper>
        </Grid>
      </Grid>

      <Accordion component={Paper}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.accordionHeading}>Search Filters</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={1} className={classes.filterGrid}>
            <Grid item lg={2} sm={3} xs={12}>
              <Typography className={classes.filterLabel}>Form Status :</Typography>
            </Grid>
            <Grid item lg={2} md={2}>
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    className={classes.checkBox}
                    size="small"
                    name="completed"
                    checked={formStatusFilters.completed}
                    onChange={handleFormStatusFilters}
                    color="Secondary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>Completed</span>}
              />
            </Grid>
            <Grid item lg={2} md={2}>
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    className={classes.checkBox}
                    size="small"
                    name="drafted"
                    checked={formStatusFilters.drafted}
                    onChange={handleFormStatusFilters}
                    color="Secondary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>Drafted</span>}
              />
            </Grid>
            <Grid item lg={2} md={2}>
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    className={classes.checkBox}
                    size="small"
                    name="pending"
                    checked={formStatusFilters.pending}
                    onChange={handleFormStatusFilters}
                    color="Secondary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>Pending</span>}
              />
            </Grid>
            {/* <Grid item lg={3} md={2}>
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    className={classes.checkBox}
                    size="small"
                    name="requestedToEdit"
                    checked={formStatusFilters.requestedToEdit}
                    onChange={handleFormStatusFilters}
                    color="Secondary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>Requested to Edit</span>}
              />
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {!backdropOpen && (
        <Fragment>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.column} align="center">
                    #
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Partner Company")}>
                    Partner Company {renderIndicator("Partner Company")}
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Reference Company")}>
                    Reference Company {renderIndicator("Reference Company")}
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Contact Email")}>
                    Contact Email {renderIndicator("Contact Email")}
                  </TableCell>
                  <TableCell className={classes.column} onClick={() => setSortColumnValue("Form Status")}>
                    Form Status {renderIndicator("Form Status")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTradeReferences.length > 0 &&
                  filteredTradeReferences
                    .filter(
                      (item) =>
                        (item.companyName
                          ? (item.companyName || "").toLowerCase().includes((searchInput || "").toLowerCase())
                          : (item.tempCompanyName || "").toLowerCase().includes((searchInput || "").toLowerCase())) ||
                        (item.resellerCompanyName || "").toLowerCase().includes((searchInput || "").toLowerCase()) ||
                        (item.email || "").toLowerCase().includes((searchInput || "").toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tradeRef, index) => (
                      <TableRow key={index} onClick={() => openTradeRef(tradeRef)} className={classes.tableRow1}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell>{tradeRef.resellerCompanyName}</TableCell>
                        <TableCell>{tradeRef.companyName || tradeRef.tempCompanyName}</TableCell>
                        <TableCell>{tradeRef.email}</TableCell>
                        <TableCell>
                          <Typography
                            className={tradeRef.status === "drafted" ? classes.secondaryText : classes.primaryText}
                          >
                            {tradeRef.status === "completed"
                              ? "completed"
                              : tradeRef.status === "pending"
                              ? "requested to edit"
                              : tradeRef.status === "drafted"
                              ? "drafted"
                              : "pending"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTradeReferences.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}

      {!backdropOpen &&
        filteredTradeReferences.filter(
          (item) =>
            (item.companyName
              ? (item.companyName || "").toLowerCase().includes((searchInput || "").toLowerCase())
              : (item.tempCompanyName || "").toLowerCase().includes((searchInput || "").toLowerCase())) ||
            (item.resellerCompanyName || "").toLowerCase().includes((searchInput || "").toLowerCase()) ||
            (item.email || "").toLowerCase().includes((searchInput || "").toLowerCase())
        ).length === 0 && (
          <Typography className={classes.msgTypo} variant="body2">
            No Trade References Found
          </Typography>
        )}
    </div>
  );
};

export default TradeReferences;
