import React, { useState, useEffect, useReducer } from "react";
import { makeStyles, createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Paper,
  Button,
  Snackbar,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  AppConfig,
  TR_SUB_QUESTION_IDS,
  CATEGORY3_QUESTION_IDS,
  BOOLEAN_DESC_SUB_QUESTION_RANGE,
  FORM_STATUS,
  SIGNATORY_CATEGORY_ID,
  SIGNED_FORM_SUB_QUESTION_ID,
  SIGNED_FORM_QUESTION_ID,
} from "../../../configs/client-config";
import QuestionDetails from "./QuestionDetails";
import CompanyInformation from "./CompanyInformation";
import { useHistory } from "react-router-dom";
import {
  getUserEmail,
  setEncodeValue as setGlobalEncodeValue,
  getEncodeValue,
  setUserEmail,
  getAPIKey,
} from "../../../utils/utils";
import useHttp from "../../../utils/http";
import PDFCreator from "../PDFCreator";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CustomSnackbar from "../../Snackbar";
import { getAccessToken } from "../../../utils/oauth";

const filePickerTheme = createTheme({
  palette: {
    primary: {
      main: "#465867",
    },
    secondary: {
      main: "#F44336",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  backgroundColor: {
    backgroundColor: "#F7F7F7",
    minHeight: "800px",
  },
  container: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  title: {
    textAlign: "center",
    paddingTop: 35,
  },
  marginTop30: {
    marginTop: 30,
  },
  justifyText: {
    textAlign: "justify",
  },
  body: {
    marginTop: 25,
    marginBottom: 25,
  },
  paper: {
    marginTop: 20,
  },
  accordion: {},
  circularProgressBar: {
    marginRight: 15,
  },
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  accordionDetails: {
    padding: "1.5% 4%",
    paddingBottom: 30,
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
      textAlign: "justify",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
      textAlign: "justify",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
      textAlign: "justify",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonGrid: {
    marginTop: 15,
    marginBottom: 2,
  },
  button: {
    backgroundColor: "white",
  },
  buttonText: {
    textTransform: "uppercase",
  },
  radioFormControl: {
    marginTop: 10,
  },
  radioGrid: {
    marginTop: 15,
    marginBottom: 10,
  },
}));

const ACTIONS = {
  SET_RESELLER_DATA: "SET_RESELLER_DATA",
  SET_ANSWERS: "SET_ANSWERS",
  SET_COMPANY_INFO: "SET_COMPANY_INFO",
  SET_SUBMIT_FILES: "SET_SUBMIT_FILES",
  HANDLE_RESET: "HANDLE_RESET",
};

const resellerReducer = (curState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ANSWERS:
      return { ...curState, answers: action.answers };
    case ACTIONS.SET_COMPANY_INFO:
      return { ...curState, companyInfo: action.companyInfo };
    case ACTIONS.SET_SUBMIT_FILES:
      return { ...curState, submitFiles: action.submitFiles };
    case ACTIONS.SET_RESELLER_DATA:
      return { ...curState, answers: action.answers, companyInfo: action.companyInfo, submitFiles: action.submitFiles };
    case ACTIONS.HANDLE_RESET:
      return { ...curState, answers: [], companyInfo: null, submitFiles: [] };
    default:
      throw new Error("Should not get here");
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ResellerForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [email, setEmail] = useState(null);
  const [encodeValue, setEncodeValue] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formRender, setFormRender] = useState(0);
  const [successSnack, setSuccess] = useState(false);
  const [formErrorSnack, setFormError] = useState(false);
  const [dataRetrieveSnack, setDataRetrieveSnack] = useState(false);
  const [agreeConditionWarn, setAgreeConditionWarn] = useState(false);
  const [file, setFile] = useState(null);
  const [failSnack, setFail] = useState(false);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [isDrafted, setIsDrafted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [companyInfoCompleted, setCompanyInfoCompleted] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("files");
  const [checkBox, setCheckBox] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [formSigned, setFormSigned] = useState(false);
  const [TrEditRequested, setTrEditRequested] = useState(false);
  const [year1, setYear1] = useState({
    companyId: "",
    year: 1,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [year2, setYear2] = useState({
    companyId: "",
    year: 2,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [year3, setYear3] = useState({
    companyId: "",
    year: 3,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [initialAnswers, setInitialAnswers] = useState([]);
  const [scale, setScale] = useState(1);
  const [backdropOpen, setbackDrop] = useState(true);
  const [backdropOpen2, setbackDrop2] = useState(false);
  const [backdropOpen3, setbackDrop3] = useState(false);
  const [closed, setClosed] = useState([]);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = React.useState(false);
  const [cpi, setCpi] = useState(0);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "",
    onClose: null,
  });

  const [{ companyInfo, submitFiles, answers }, dispatchReseller] = useReducer(resellerReducer, {
    companyInfo: null,
    submitFiles: [],
    answers: [],
  });

  const setAnswers = (answers) => {
    dispatchReseller({ type: ACTIONS.SET_ANSWERS, answers: answers });
  };

  const setCompanyInfo = (companyInfo) => {
    dispatchReseller({ type: ACTIONS.SET_COMPANY_INFO, companyInfo: companyInfo });
  };

  const setSubmitFiles = (submitFiles) => {
    dispatchReseller({ type: ACTIONS.SET_SUBMIT_FILES, submitFiles: submitFiles });
  };

  const handleSubmitFiles = (file) => {
    var tempFiles = submitFiles.slice();
    tempFiles.push(file);
    dispatchReseller({ type: ACTIONS.SET_SUBMIT_FILES, submitFiles: tempFiles });
  };

  const handleDeleteFiles = (fileName) => {
    const updatedSubmitFiles = submitFiles.filter((item) => item.fileName !== fileName);
    dispatchReseller({ type: ACTIONS.SET_SUBMIT_FILES, submitFiles: updatedSubmitFiles });
  };

  useEffect(() => {
    if (props.location && props.location.state && props.location.setSuccessMsg) {
      setSuccess(true);
    }
  }, []);

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.getResellerData:
        if (data && !error) {
          var tempAnswers = [];
          var tempSubmitFiles = [];
          var tempCompanyInfo = null;

          if (data.files && data.files.length > 0) {
            tempSubmitFiles = data.files;
          }
          if (data.company && data.company.length > 0) {
            tempCompanyInfo = data.company[0];
            if (
              data.company[0].formStatus === FORM_STATUS.COMPLETED ||
              data.company[0].formStatus === FORM_STATUS.EDIT_COMPLETED ||
              data.company[0].formStatus === FORM_STATUS.PENDING
            ) {
              setFormCompleted(true);
            }
            if (
              data.company[0].formStatus === FORM_STATUS.TR_EDIT_REQUESTED ||
              data.company[0].formStatus === FORM_STATUS.TR_DRAFTED
            ) {
              setFormCompleted(true);
              setTrEditRequested(true);
            }
            if (data.company[0].formStatus === FORM_STATUS.SIGNED) {
              setFormCompleted(true);
              setFormSigned(true);
            }
          }
          if (data.answers && data.answers.length > 0) {
            // Trade reference data (Question ID - 18)
            // Contact Name (Person who filled the form) (Question ID - 24, 25, 26) (Category 5)
            // Final 2 Descriptive questions under legal (Question ID - 72, 73)
            tempAnswers = data.answers;
            setInitialAnswers(tempAnswers);
            let result = data.answers.filter((item) => item.questionId === 18);
            // If the question ID is equal to 18 -> Set trade reference data
            if (result.length > 0) {
              setSelectedRadio("references");
            }
          }
          dispatchReseller({
            type: ACTIONS.SET_RESELLER_DATA,
            answers: tempAnswers,
            submitFiles: tempSubmitFiles,
            companyInfo: tempCompanyInfo,
          });
        } else if (error) {
          setCompanyInfo(null);
          setbackDrop(false);
          console.error(error);
        } else {
          setbackDrop(false);
        }
        break;
      case AppConfig.getResellerQuestionData:
        if (data) {
          if (data.subQuestions) {
            setSubQuestions(data.subQuestions);
          }
          if (data.questions) {
            setQuestions(data.questions);
          }
          if (data.questionCategories) {
            setQuestionCategories(data.questionCategories);
          }
        } else if (error) {
          setDataRetrieveSnack(true);
          setbackDrop(false);
          console.error(error);
        } else {
          setbackDrop(false);
        }
        break;
      case AppConfig.updateResellerInfo:
        if (data && !error) {
          if (selectedRadio === "references") {
            insertTradeRefLinks(data.companyData.companyName);
          } else {
            let creditItems = [];
            creditItems.push(mapToScale(year1));
            creditItems.push(mapToScale(year2));
            creditItems.push(mapToScale(year3));
            insertCreditCheckList(creditItems);
            setSuccess(true);
          }
          setbackDrop3(false);
        } else if (error) {
          console.error(error?.message);
          setSnackbarData({
            message: error,
            open: true,
            severity: "error",
            onClose: onCloseSnackbar,
          });
          setbackDrop3(false);
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  const handleChange = (index) => (event, newExpanded) => {
    if (closed.includes(index)) {
      setClosed(closed.filter((item) => item !== index));
    } else {
      setClosed((prev) => [...prev, index]);
    }
  };

  const getResellerData = async () => {
    setbackDrop2(true);
    const requestURL =
      AppConfig.baseUrl + AppConfig.getResellerData + "?encodeValue=" + getEncodeValue() + "&email=" + getUserEmail();
    sendRequest(requestURL, "GET", null, AppConfig.getResellerData);
  };

  const getResellerInfoQuestions = async () => {
    const requestURL =
      AppConfig.baseUrl +
      AppConfig.getResellerQuestionData +
      getUserEmail() +
      "/questions?encodeValue=" +
      getEncodeValue();
    sendRequest(requestURL, "GET", null, AppConfig.getResellerQuestionData);
  };

  useEffect(() => {
    setbackDrop(true);
    if (props.encodeValue) {
      setGlobalEncodeValue(props.encodeValue);
      setEncodeValue(props.encodeValue);
      setUserEmail(props.email);
      getResellerData();
    }

    if (props.email && props.encodeValue) {
      getCpiData();
      setGlobalEncodeValue(props.encodeValue);
      setEncodeValue(props.encodeValue);
      setUserEmail(props.email);
      setEmail(props.email);
      getResellerInfoQuestions();
      getResellerData();
    }
  }, [props.encodeValue, formRender, props.email]);

  useEffect(() => {
    if (email && encodeValue) {
      getResellerInfoQuestions();
    }
  }, [email, encodeValue]);

  // callback function to get Company Information
  const companyInfoCallback = (data, formCompleted) => {
    setCompanyInfoCompleted(formCompleted);
  };

  const saveFile = async (file) => {
    setbackDrop3(true);
    setOpenDocumentUpload(false);
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);

    const fileUploadRequestURL =
      AppConfig.baseUrl +
      "partners/" +
      getUserEmail() +
      "/" +
      AppConfig.fileUpload +
      "?encodeValue=" +
      getEncodeValue() +
      "&fileExtension=" +
      fileExtension +
      "&fileName=" +
      fileName;

    try {
      let response = await fetch(fileUploadRequestURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: file,
      });
      if (response.status !== 200) {
        throw new Error("File upload failed. Please try again.");
      }
      let body = await response.json();

      let fileInfo = {
        questionId: SIGNED_FORM_QUESTION_ID,
        subQuestionId: SIGNED_FORM_SUB_QUESTION_ID,
        fileName: body.data.fileName,
        fileType: body.data.fileType,
      };
      const requestURL =
        AppConfig.baseUrl + AppConfig.insertSignedFormData + "/" + getUserEmail() + "?encodeValue=" + getEncodeValue();

      response = await fetch(requestURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(fileInfo),
      });
      body = await response.json();
      if (response.status !== 200) {
        throw new Error("File update failed. Please try again.");
      }
      setSuccess(true);
      window.location.reload();
    } catch (error) {
      console.error(error);
      setSnackbarData({
        message: error,
        open: true,
        severity: "error",
        onClose: onCloseSnackbar,
      });
    } finally {
      setbackDrop3(false);
    }
  };

  // callback function to get Answers for the questions
  const answersCallback = (subQuestionId, questionId, booleanAnswer, descAnswer, files, isValid) => {
    var tempAnswers = answers.filter((item) => item.subQuestionId !== subQuestionId);
    var tempSubmitFiles = submitFiles.filter((item) => item.subQuestionId !== subQuestionId);

    // remove previous answers from the array
    // remove previous files from the array
    setSubmitFiles(tempSubmitFiles);
    if (isValid) {
      tempAnswers.push({
        subQuestionId: subQuestionId,
        questionId: questionId,
        booleanAnswer: Boolean(booleanAnswer),
        descriptionAnswer: descAnswer,
        fileCount: files.length,
      });
      setAnswers(tempAnswers);
      if (files.length > 0) {
        files.map((file) => {
          tempSubmitFiles = submitFiles.slice();
          tempSubmitFiles.push({
            subQuestionId: subQuestionId,
            questionId: questionId,
            fileName: file.fileName,
            fileType: file.fileType,
          });
          setSubmitFiles(tempSubmitFiles);
          return null;
        });
      }
    } else {
      setAnswers(tempAnswers);
    }
  };

  const creditCheckListCallback = (year1, year2, year3) => {
    setYear1(year1);
    setYear2(year2);
    setYear3(year3);
  };

  const creditScaleCallback = (scale) => {
    setScale(scale);
  };

  const validateRequiredAnswers = () => {
    let finalResult = true;
    const questionIds = questions.map((question) => question.questionId);
    questionCategories.map((category) => {
      // validating category 3 answers
      if (category.categoryId === 3) {
        if (selectedRadio === "files") {
          let filteredSubQuestions = subQuestions.filter((item) => item.questionId === 17);
          filteredSubQuestions.map((subQuestion) => {
            if (subQuestion.answerRequired) {
              let isInAnswers = answers.some(
                (answer) => answer.subQuestionId === subQuestion.subQuestionId && answer.descriptionAnswer !== ""
              );
              let isInSubmitFiles = submitFiles.some((file) => file.subQuestionId === subQuestion.subQuestionId);
              if (!isInAnswers && !isInSubmitFiles) {
                finalResult = false;
              }
            }
          });
        }
        if (selectedRadio === "references") {
          let filteredSubQuestions = subQuestions.filter((item) => item.questionId === 18 || item.questionId === 19);
          filteredSubQuestions.map((subQuestion) => {
            if (subQuestion.answerRequired) {
              let result = answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId);
              if (result.length === 0) {
                finalResult = false;
              }
            }
          });
        }
      } else {
        let filteredSubQuestions = subQuestions.filter(
          (item) => !CATEGORY3_QUESTION_IDS.includes(item.questionId) && questionIds.includes(item.questionId)
        );
        filteredSubQuestions.map((subQuestion) => {
          if (subQuestion.answerRequired) {
            let isInAnswers = answers.some(
              (answer) =>
                answer.subQuestionId === subQuestion.subQuestionId &&
                (subQuestion.subQuestionId >= BOOLEAN_DESC_SUB_QUESTION_RANGE.start &&
                subQuestion.subQuestionId <= BOOLEAN_DESC_SUB_QUESTION_RANGE.end
                  ? !(answer.booleanAnswer == true && answer.descriptionAnswer === "")
                  : answer.descriptionAnswer !== "")
            );
            let isInSubmitFiles = submitFiles.some((file) => file.subQuestionId === subQuestion.subQuestionId);
            if (!isInAnswers && !isInSubmitFiles) {
              finalResult = false;
            }
          }
        });
      }
    });

    return finalResult;
  };

  const checkObjectComplete = (obj) => {
    for (var key in obj) {
      if (obj[key] === "") return false;
    }
    return true;
  };

  const mapToScale = (obj) => {
    const object = JSON.parse(JSON.stringify(obj));
    for (var key in object) {
      if (object[key] === "" || object[key] === null) {
        object[key] = 0;
      }
      // If not company id or year add scale values after entered credit score
      if (!(key === "companyId" || key === "year")) {
        object[key] = object[key] * scale;
      }
    }
    return object;
  };

  const requestToEdit = async () => {
    setbackDrop(true);
    const companyId = props.match.params.id;
    const requestURL =
      AppConfig.baseUrl +
      AppConfig.getResellerData +
      "/" +
      getUserEmail() +
      AppConfig.updateResellerFormStatus +
      "?encodeValue=" +
      getEncodeValue();

    let data = {
      formStatus: "pending",
      clientUrl: AppConfig.baseUrl,
    };

    try {
      let response = await fetch(requestURL, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          apikey: getAPIKey(),
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      setbackDrop(false);
      if (response.status === 200) {
        setSuccess(true);
        setFormRender(formRender + 1);
      } else {
        console.error(body.message);
        setSnackbarData({
          message: body.message,
          open: true,
          severity: "error",
          onClose: onCloseSnackbar,
        });
      }
      return null;
    } catch (error) {
      setSnackbarData({
        message: error,
        open: true,
        severity: "error",
        onClose: onCloseSnackbar,
      });
      console.error(error);
      return error;
    }
  };

  const getCategoryTwoQA = () => {
    var qaMap = {};
    questions.forEach((question) => {
      if (question.categoryId === 2) {
        // Legal (Yes/No) Questions
        qaMap[question?.questionId] = {
          question: question,
        };
      }
    });
    answers.forEach((answer) => {
      if (answer?.questionId && qaMap[answer.questionId]) {
        var tempObject = qaMap[answer.questionId];
        tempObject["answer"] = answer;
        qaMap[answer.questionId] = tempObject;
      }
    });
    subQuestions.forEach((subQuestion) => {
      if (subQuestion?.questionId && qaMap[subQuestion.questionId]) {
        var tempObject = qaMap[subQuestion.questionId];
        tempObject["subQuestion"] = subQuestion;
        qaMap[subQuestion.questionId] = tempObject;
      }
    });
    return qaMap;
  };

  const getSignatoryAnswers = () => {
    var answersObject = {};
    questions.forEach((question) => {
      if (question.categoryId === SIGNATORY_CATEGORY_ID) {
        answers.forEach((answer) => {
          if (question.questionId && answer?.questionId !== undefined && answer.questionId === question.questionId) {
            if (question?.questionDescription && answer.descriptionAnswer) {
              answersObject[question?.questionDescription] = answer.descriptionAnswer;
            }
          }
        });
      }
    });

    return answersObject;
  };

  // Save as Draft Button Function caller
  const saveDraft = (event) => {
    setIsDrafted(true);
    setIsSubmitted(false);

    // Validating Category 05 fields as Required
    let validated = true;

    questions.map((question) => {
      if (question.categoryId === 5) {
        subQuestions.map((subQuestion) => {
          if (question.questionId === subQuestion.questionId) {
            let filterResult = answers.filter((item) => item.subQuestionId === subQuestion.subQuestionId).length > 0;
            if (!filterResult) {
              validated = false;
            }
          }

          return null;
        });
      }

      return null;
    });

    // If category 05 fields are not validated
    if (!validated) {
      setFormError(true);
    } else {
      let tempInfo = companyInfo;

      if (tempInfo.country === "United States" || tempInfo.country === "Canada") {
        if (
          tempInfo.companyName &&
          tempInfo.streetAddress &&
          tempInfo.state &&
          tempInfo.cityProvince &&
          tempInfo.postalZipCode &&
          tempInfo.country
        ) {
          // set field value empty if it's value is false
          Object.keys(tempInfo).forEach(function (key) {
            if (tempInfo[key] === false) {
              tempInfo[key] = "";
            }
          });
          setCompanyInfo(tempInfo);
          submitData(TrEditRequested ? FORM_STATUS.TR_DRAFTED : FORM_STATUS.DRAFTED);
        } else {
          setFormError(true);
        }
      } else {
        if (
          tempInfo.companyName &&
          tempInfo.streetAddress &&
          tempInfo.cityProvince &&
          tempInfo.postalZipCode &&
          tempInfo.country
        ) {
          // set field value empty if it's value is false
          Object.keys(tempInfo).forEach(function (key) {
            if (tempInfo[key] === false) {
              tempInfo[key] = "";
            }
          });
          setCompanyInfo(tempInfo);
          submitData(TrEditRequested ? FORM_STATUS.TR_DRAFTED : FORM_STATUS.DRAFTED);
        } else {
          setFormError(true);
        }
      }
    }
  };

  const submitData = async (formStatus) => {
    setbackDrop3(true);
    let companyData = {
      ...companyInfo,
      formStatus: formStatus,
    };

    let data = {
      companyData: companyData,
      answers: answers,
      submitFiles: submitFiles,
    };

    const requestURL =
      AppConfig.baseUrl + AppConfig.insertResellerInfo + "/" + getUserEmail() + "?encodeValue=" + getEncodeValue();
    try {
      let response = await fetch(requestURL, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      if (response.status === 200) {
        if (
          selectedRadio === "references" &&
          (formStatus === FORM_STATUS.COMPLETED || formStatus === FORM_STATUS.EDIT_COMPLETED)
        ) {
          insertTradeRefLinks(data.companyData.companyName);
        } else {
          let creditItems = [];

          creditItems.push(mapToScale(year1));
          creditItems.push(mapToScale(year2));
          creditItems.push(mapToScale(year3));
          await insertCreditCheckList(creditItems);

          setSuccess(true);
        }
        setTrEditRequested(false);
      } else {
        console.error(body.message);
        setSnackbarData({
          message: body.message,
          open: true,
          severity: "error",
          onClose: onCloseSnackbar,
        });
      }
      setbackDrop3(false);

      return null;
    } catch (error) {
      setbackDrop3(false);
      setSnackbarData({
        message: error,
        open: true,
        severity: "error",
        onClose: onCloseSnackbar,
      });
      console.error(error);
      return error;
    }
  };

  // Insert Trade Reference Information into DB
  const insertTradeRefLinks = async (companyName) => {
    let idList1 = [];
    let idList2 = [];
    let idList3 = [];

    // Filtering Category 3 Sub questions containing email addresses
    let category3Questions = questions.filter((item) => item.categoryId === 3);
    category3Questions.map((question) => {
      subQuestions.map((subQuestion) => {
        if (question.questionId === subQuestion.questionId && subQuestion.description === "E-mail") {
          idList1.push(subQuestion.subQuestionId);
        }
        if (question.questionId === subQuestion.questionId && subQuestion.description === "Company name") {
          idList2.push(subQuestion.subQuestionId);
        }
        if (question.questionId === subQuestion.questionId && subQuestion.description === "Address") {
          idList3.push(subQuestion.subQuestionId);
        }
      });
    });
    let data = [];

    let email = "";
    let tempReferenceCompanyName = "";
    let tempReferenceCompanyAddress = "";

    // Get Trade Reference 01 Information
    email = answers.filter((answer) => answer.subQuestionId === idList1[0])[0]?.descriptionAnswer;
    tempReferenceCompanyName = answers.filter((answer) => answer.subQuestionId === idList2[0])[0]?.descriptionAnswer;
    tempReferenceCompanyAddress = answers.filter((answer) => answer.subQuestionId === idList3[0])[0]?.descriptionAnswer;

    // Check if first Trade Reference company name or email has changed
    let isTRChanged =
      tempReferenceCompanyName !==
        initialAnswers.find((a) => a.subQuestionId === TR_SUB_QUESTION_IDS.TR1_COMPANY_NAME)?.descriptionAnswer ||
      email !== initialAnswers.find((a) => a.subQuestionId === TR_SUB_QUESTION_IDS.TR1_EMAIL)?.descriptionAnswer;

    const company1 = {
      companyName: companyName ? companyName : "",
      clientUrl: AppConfig.baseUrl,
      email: email ? email : "",
      tempReferenceCompanyAddress: tempReferenceCompanyAddress ? tempReferenceCompanyAddress : "",
      tempReferenceCompanyName: tempReferenceCompanyName ? tempReferenceCompanyName : "",
      isTRChanged: isTRChanged,
    };
    data.push(company1);

    // Get Trade Reference 02 Information
    email = answers.filter((answer) => answer.subQuestionId === idList1[1])[0]?.descriptionAnswer;
    tempReferenceCompanyName = answers.filter((answer) => answer.subQuestionId === idList2[1])[0]?.descriptionAnswer;
    tempReferenceCompanyAddress = answers.filter((answer) => answer.subQuestionId === idList3[1])[0]?.descriptionAnswer;

    // Check if second Trade Reference company name or email has changed
    isTRChanged =
      tempReferenceCompanyName !==
        initialAnswers.find((a) => a.subQuestionId === TR_SUB_QUESTION_IDS.TR2_COMPANY_NAME)?.descriptionAnswer ||
      email !== initialAnswers.find((a) => a.subQuestionId === TR_SUB_QUESTION_IDS.TR2_EMAIL)?.descriptionAnswer;

    const company2 = {
      companyName: companyName ? companyName : "",
      clientUrl: AppConfig.baseUrl,
      email: email ? email : "",
      tempReferenceCompanyAddress: tempReferenceCompanyAddress ? tempReferenceCompanyAddress : "",
      tempReferenceCompanyName: tempReferenceCompanyName ? tempReferenceCompanyName : "",
      isTRChanged: isTRChanged,
    };

    data.push(company2);

    const requestURL =
      AppConfig.baseUrl + AppConfig.tradeRefLinks + "?encodeValue=" + getEncodeValue() + "&email=" + getUserEmail();
    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();

      setbackDrop3(false);
      if (response.status === 200) {
        setSuccess(true);
        setbackDrop3(false);
        setFormRender(formRender + 1);
      } else {
        console.error(body.message);
        setSnackbarData({
          message: body.message,
          open: true,
          severity: "error",
          onClose: onCloseSnackbar,
        });
      }
      setbackDrop3(false);
      return null;
    } catch (error) {
      setSnackbarData({
        message: error,
        open: true,
        severity: "error",
        onClose: onCloseSnackbar,
      });

      setbackDrop3(false);
      return error;
    }
  };

  const insertCreditCheckList = async (data) => {
    const requestURL =
      AppConfig.baseUrl +
      AppConfig.insertCreditScoreItems +
      "?encodeValue=" +
      getEncodeValue() +
      "&email=" +
      getUserEmail();
    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify({
          items: data,
        }),
      });
      let body = await response.json();
      if (response.status === 200) {
        setFormRender(formRender + 1);
        setbackDrop3(false);
      } else {
        console.error(body.message);
        setSnackbarData({
          message: body.message,
          open: true,
          severity: "error",
          onClose: onCloseSnackbar,
        });
      }

      setbackDrop3(false);
      return null;
    } catch (error) {
      setbackDrop3(false);
      console.error(error);
      setSnackbarData({
        message: error,
        open: true,
        severity: "error",
        onClose: onCloseSnackbar,
      });
      return error;
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);

    const category3QuestionIds = questions
      .filter((question) => question.categoryId === 3)
      .map((question) => question.questionId);

    const filteredAnswers = answers.filter((item) => !category3QuestionIds.includes(item.questionId));
    const filteredSubmitFiles = submitFiles.filter((item) => !category3QuestionIds.includes(item.questionId));

    setAnswers(filteredAnswers);
    setSubmitFiles(filteredSubmitFiles);
  };

  const handleCheckbox = (event) => {
    setCheckBox(event.target.checked);
  };

  // Filter questions for financial Radio
  const financialRadioFilter = (categoryId, questionId) => {
    if (categoryId === 3) {
      if (selectedRadio === "files" && questionId === 17) {
        return true;
      }
      if (selectedRadio === "references" && (questionId === 18 || questionId === 19)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getCpiData = async () => {
    const requestURL =
      AppConfig.baseUrl + AppConfig.cpiData + "?email=" + getUserEmail() + "&encodeValue=" + getEncodeValue();

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });

      let data = await response.json();
      if (response.status === 200) {
        setCpi(parseInt(data));
      } else {
        console.warn("No CPI data retrieved");
      }
    } catch (error) {
      console.error("Failed to fetch CPI data: ", error);
    }
  };

  // Accept conditions and open compliance form
  const openComplianceForm = () => {
    setIsNextClicked(true);
    setIsSubmitted(true);
    if (
      companyInfoCompleted &&
      validateRequiredAnswers() &&
      (selectedRadio === "references" ||
        (checkObjectComplete(year1) && checkObjectComplete(year2) && checkObjectComplete(year3)))
    ) {
      if (checkBox) {
        setOpenConfirmation(true);
      } else {
        setAgreeConditionWarn(true);
      }
    } else {
      setFormError(true);
    }
  };

  // Upload signed document
  const handleFileUpload = () => {
    if (file) {
      saveFile(file);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const submitConfirmation = async () => {
    try {
      setOpenConfirmation(false);
      await submitData(
        companyInfo.formStatus === FORM_STATUS.TR_EDIT_REQUESTED ||
          companyInfo.formStatus === FORM_STATUS.FULL_EDIT_REQUESTED
          ? FORM_STATUS.EDIT_COMPLETED
          : FORM_STATUS.COMPLETED
      );
      setDialogOpen(true);
    } catch (error) {
      console.error("Submission failed", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDownload = () => {
    setDialogOpen(false);
    window.scrollTo(0, 0);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setIsNextClicked(false);
    setFormError(false);
    setAgreeConditionWarn(false);
    setDataRetrieveSnack(false);
  };

  const onCloseSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Backdrop open={backdropOpen2} className={classes.backdrop2}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Backdrop open={backdropOpen3} className={classes.backdrop}>
        <CircularProgress color="secondary" className={classes.circularProgressBar} />
        <Typography>Please be patient. This might take a while.</Typography>
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Snackbar open={formErrorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please check your form again!
        </Alert>
      </Snackbar>
      <Snackbar open={agreeConditionWarn} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please accept the conditions
        </Alert>
      </Snackbar>
      <Snackbar open={dataRetrieveSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Something went wrong while retrieving data
        </Alert>
      </Snackbar>
      <CustomSnackbar snackbar={snackbarData} />

      <Dialog open={openConfirmation} fullWidth>
        <DialogTitle>{"Are you sure you want to proceed to the next step?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Once you proceed, you will not be able to make any changes to the form.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" fullWidth onClick={submitConfirmation}>
            Proceed
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              setOpenConfirmation(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>{"Download the document"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please download the PDF document, sign it, and upload it back. You can revisit this link later to upload the
            signed document.
          </DialogContentText>

          <div style={{ height: "60vh", width: "100%" }}>
            <PDFViewer style={{ height: "100%", width: "100%" }} showToolbar={false}>
              <PDFCreator
                answers={companyInfo}
                nameAndDesignation={getSignatoryAnswers()}
                legalQA={getCategoryTwoQA()}
                cpi={cpi}
              />
            </PDFViewer>
          </div>
          <div>
            <PDFDownloadLink
              document={
                <PDFCreator
                  answers={companyInfo}
                  nameAndDesignation={getSignatoryAnswers()}
                  legalQA={getCategoryTwoQA()}
                  cpi={cpi}
                />
              }
              fileName="WSO2ResellerForm.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button className={classes.paper} color="secondary" variant="contained" onClick={handleDownload}>
                    Download PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDocumentUpload} onClose={handleClose}>
        <DialogTitle>Upload Signed Document</DialogTitle>
        <DialogContent>
          <DialogContentText>Please upload your signed document below.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} lg={11}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <MuiThemeProvider theme={filePickerTheme}>
                  <Button
                    className={classes.uploadButton}
                    size="small"
                    variant="outlined"
                    component="label"
                    color="primary"
                    style={{ whiteSpace: "nowrap", minWidth: "auto" }}
                  >
                    <input type="file" onChange={handleFileChange} size="large" hidden required />
                    Choose File
                  </Button>
                </MuiThemeProvider>

                {file && (
                  <Chip
                    className={classes.fileChip}
                    label={file.name && file.name.length > 25 ? file.name.substring(0, 25) + "..." : file.name}
                    color="secondary"
                    variant="outlined"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${classes.submitButton} ${classes.buttonText}`}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleFileUpload}
          >
            Submit
          </Button>
          <Button
            className={`${classes.button} ${classes.buttonText}`}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              setOpenDocumentUpload(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.backgroundColor}>
        <Container className={classes.container}>
          {subQuestions.length > 0 && (
            <form noValidate autoComplete="off">
              <div>
                <div className={classes.title}>
                  <Typography variant="h5">Partner Due Diligence</Typography>
                </div>
                {formCompleted && !TrEditRequested && !formSigned && (
                  <Alert
                    icon={false}
                    severity="warning"
                    variant="standard"
                    style={{ marginTop: "20px" }}
                    action={
                      <div>
                        <Button color="primary" style={{ marginRight: "10px" }} onClick={() => setDialogOpen(true)}>
                          Download Document
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => setOpenDocumentUpload(true)}>
                          Upload Signed Document
                        </Button>
                      </div>
                    }
                  >
                    <MuiAlertTitle>Please upload the signed document to complete the process.</MuiAlertTitle>
                  </Alert>
                )}
                <div className={classes.body}>
                  <Typography className={classes.justifyText} variant="subtitle1" gutterBottom>
                    In order to register your company as a partner, we need to conduct a Due Diligence check which
                    requires the following information from you. All information requested in this form is required to
                    be provided in full. The information declared must be true and correct.
                  </Typography>
                </div>
              </div>
              {
                <CompanyInformation
                  callback={companyInfoCallback}
                  companyInfo={companyInfo}
                  isSubmitted={isSubmitted}
                  isDrafted={isDrafted}
                  formCompleted={formCompleted}
                  setCompanyInfo={setCompanyInfo}
                />
              }
              {/* {!props.match.params.id && <CompanyInformation callback={companyInfoCallback} isSubmitted={isSubmitted} isDrafted={isDrafted} formCompleted={formCompleted} />} */}

              {questionCategories.length > 0 &&
                questionCategories.map((category) => {
                  return (
                    <Paper className={classes.paper} key={category.sortIndex}>
                      <Accordion
                        className={classes.accordion}
                        expanded={!closed.includes(category.sortIndex)}
                        onChange={handleChange(category.sortIndex)}
                      >
                        <AccordionSummary
                          className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                        >
                          <Grid item xs={12} className={classes.headingParent}>
                            <Typography className={classes.headingIndex}>{category.sortIndex}.</Typography>
                            <Typography className={classes.headingName}>{category.categoryName}</Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Grid container spacing={1}>
                            {/* Radio Button to Filter Financial Fields */}
                            {category.categoryId === 3 && (
                              <Grid item xs={12} className={classes.radioGrid}>
                                <Typography>
                                  You can either upload audited/unaudited files or reference company details as
                                  Financial Information
                                </Typography>
                                <div className={classes.headingParent}>
                                  <div className={classes.headingIndex}></div>
                                  <FormControl
                                    component="fieldset"
                                    className={classes.radioFormControl}
                                    disabled={formCompleted && !TrEditRequested}
                                  >
                                    <RadioGroup aria-label="gender" value={selectedRadio} onChange={handleRadioChange}>
                                      <FormControlLabel
                                        value="files"
                                        control={<Radio color="primary" />}
                                        label="Upload audited/unaudited files"
                                      />
                                      <FormControlLabel
                                        value="references"
                                        control={<Radio color="primary" />}
                                        label="Submit reference company details"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Grid>
                            )}
                            {questions.length > 0 &&
                              subQuestions.length > 0 &&
                              (() => {
                                let questionCounter = 0;
                                return questions.map((question, index) => {
                                  if (
                                    question.categoryId === category.categoryId &&
                                    financialRadioFilter(category.categoryId, question.questionId)
                                  ) {
                                    questionCounter++;
                                    return (
                                      <QuestionDetails
                                        email={getUserEmail()}
                                        encodeValue={getEncodeValue()}
                                        index={questionCounter}
                                        subQuestions={subQuestions}
                                        question={question}
                                        category={category}
                                        key={question.questionId}
                                        parentCallback={answersCallback}
                                        answers={answers}
                                        isSubmitted={isSubmitted}
                                        files={submitFiles}
                                        isDrafted={isDrafted}
                                        formCompleted={formCompleted}
                                        TrEditRequested={TrEditRequested}
                                        creditCheckListCallback={creditCheckListCallback}
                                        creditScaleCallback={creditScaleCallback}
                                        handleSubmitFiles={handleSubmitFiles}
                                        handleDeleteFiles={handleDeleteFiles}
                                        status={companyInfo?.formStatus}
                                      />
                                    );
                                  } else return null;
                                });
                              })()}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  );
                })}

              {/* Bottom section of form */}
              {(!companyInfo?.formStatus ||
                companyInfo?.formStatus === FORM_STATUS.DRAFTED ||
                companyInfo?.formStatus === FORM_STATUS.TR_DRAFTED ||
                companyInfo?.formStatus === FORM_STATUS.TR_EDIT_REQUESTED ||
                companyInfo?.formStatus === FORM_STATUS.FULL_EDIT_REQUESTED) && (
                <Grid className={classes.buttonGrid} container direction={"row"} spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheckbox} checked={checkBox} name="checkBox" />}
                      label="I hereby confirm to WSO2 that the information provided herein is true, accurate and complete and that the documents submitted along with this form are genuine"
                    ></FormControlLabel>
                  </Grid>

                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Button
                      className={`${classes.submitButton} ${classes.buttonText}`}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={openComplianceForm}
                    >
                      Next
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={2}>
                    <Button
                      className={`${classes.button} ${classes.buttonText}`}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={saveDraft}
                    >
                      Save Draft
                    </Button>
                  </Grid>
                </Grid>
              )}

              {/* {companyInfo?.formStatus && companyInfo.formStatus === "completed" && (
                <Grid className={classes.buttonGrid} container direction={"row"} spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.justifyText} variant="subtitle1" gutterBottom>
                      If you want to edit the form, you have to request and wait until WSO2 team mate gives you the
                      access to edit.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={2}>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      onClick={requestToEdit}
                      fullWidth
                    >
                      Request to Edit
                    </Button>
                  </Grid>
                </Grid>
              )} */}

              {companyInfo?.formStatus && companyInfo.formStatus === "pending" && (
                <Grid className={classes.buttonGrid} container direction={"row"} spacing={2}>
                  <Grid item xs={12} sm={3} md={3} lg={2}>
                    <Button className={classes.button} variant="outlined" color="primary" fullWidth disabled>
                      Already Requested
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.justifyText} variant="subtitle1" gutterBottom>
                      ( Please wait until WSO2 team mate gives you the access to edit the form )
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResellerForm;
