import React, { useEffect, useState } from "react";
import useHttp from "../../../utils/http";
import { makeStyles } from "@material-ui/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Button,
  Grid,
  Chip,
  Backdrop,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Select,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppConfig,
  CurrencyList,
  TR_SUB_QUESTION_IDS,
  CATEGORY3_QUESTION_IDS,
  FORM_STATUS,
  SIGNATORY_CATEGORY_ID,
} from "../../../configs/client-config";
import CreditChecklist from "./CreditChecklist";
import { getEncodeValue, getUserEmail } from "../../../utils/utils";
import CustomSnackbar from "../../Snackbar";

const fileUploadLimit = 5;

const filePickerTheme = createTheme({
  palette: {
    primary: {
      main: "#465867",
    },
    secondary: {
      main: "#F44336",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  questionParent: {
    display: "flex",
  },
  questionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  questionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  emailMsg: {
    color: "grey",
  },
  fileAttachment: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  fileChip: {
    marginLeft: 10,
  },
  uploadButton: {
    textTransform: "none",
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 5,
    width: "100%",
  },
}));

const SubQuestionDetails = (props) => {
  const classes = useStyles();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen, handleRequest } = useHttp();
  const [question, setQuestion] = useState(props.question);
  const [subQuestion, setSubQuestion] = useState(props.subQuestion);
  const [category, setCategory] = useState(props.category);
  const [backdropOpen, setbackDrop] = useState(false);
  const [fileUploadSnack, setFileUploadSnack] = useState(false);
  const [fileUploadContentSnack, setFileUploadContentSnack] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fieldChanged, setFieldChanged] = useState(false);
  const [boolAnswer, setBoolAnswer] = useState(undefined);
  const [descAnswer, setDescriptionAnswer] = useState("");
  const [scale, setScale] = useState(0);
  const [uploadedFiles, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "",
    onClose: null,
  });

  useEffect(() => {
    if (props.answer && props.answer.length > 0 && props.answer[0].subQuestionId == 11) {
      let scale = props.answer[0].descriptionAnswer;

      if (scale === "Actual") {
        setScale(1);
        props.creditScaleCallback(1);
      } else if (scale === "Thousands (000)") {
        setScale(1000);
        props.creditScaleCallback(1000);
      } else if (scale === "Millions (000,000)") {
        setScale(1000000);
        props.creditScaleCallback(1000000);
      } else if (scale === "Billions (000,000,000)") {
        setScale(1000000000);
        props.creditScaleCallback(1000000000);
      }
    }

    // Converting Mysql 0/1 into Boolean True/False
    if (props.answer && props.answer[0] && props.answer[0].booleanAnswer) {
      setBoolAnswer(true);
    } else if (props.answer && props.answer[0] && !props.answer[0].booleanAnswer) {
      setBoolAnswer(false);
    }
    // If Answer is not available, set empty String
    if (props.answer && props.answer.length === 0) {
      setDescriptionAnswer("");
    }
    // If answer is available
    else if (props.answer && props.answer[0]) {
      setDescriptionAnswer(props.answer[0].descriptionAnswer);
    }

    if (props.files && props.files.length > 0) {
      var filesToSet = [];
      props.files.forEach((file) => {
        if (file.subQuestionId === subQuestion.subQuestionId) {
          filesToSet.push(file);
        }
        return null;
      });
      setFiles(filesToSet);
    }

    setLoading(false);
  }, [
    props.question,
    props.subQuestion,
    props.category,
    props.answer,
    props.files,
    props.subQuestionId,
    props.questionId,
  ]);

  // Handle Radio Button Inputs
  const handleRadioChange = (subQuestionId) => (event) => {
    const value = event.target.value;
    // If yes is selected
    if (value === "yes") {
      setBoolAnswer(true);
      setDescriptionAnswer("");
      props.parentCallback(subQuestionId, true, "", "", true, subQuestion.answerRequired);
    }
    // If no is selected
    else {
      setBoolAnswer(false);
      setDescriptionAnswer("");
      props.parentCallback(subQuestionId, false, "", "", true, subQuestion.answerRequired);
    }
  };

  // Handle Text Inputs
  const handleFormChange = (subQuestionId) => (event) => {
    if (subQuestionId === 11) {
      setScale(event.target.value);
      let scale = event.target.value;
      if (scale === "Actual") {
        props.creditScaleCallback(1);
      } else if (scale === "Thousands (000)") {
        props.creditScaleCallback(1000);
      } else if (scale === "Millions (000,000)") {
        props.creditScaleCallback(1000000);
      } else if (scale === "Billions (000,000,000)") {
        props.creditScaleCallback(1000000000);
      }
    }
    setDescriptionAnswer(event.target.value);
    setFieldChanged(true);
    // If yes/no and description answer type
    if (subQuestion.answerType) {
      if (boolAnswer) {
        // If description answer is empty
        if ((event.target.value === "" || event.target.value === false) && subQuestion.answerRequired) {
          props.parentCallback(subQuestionId, true, event.target.value, "", false, subQuestion.answerRequired);
        } else {
          // If answer is valid
          if (validateField2(event.target.value) === "") {
            props.parentCallback(subQuestionId, true, event.target.value, "", true, subQuestion.answerRequired);
          }
          //If answer is invalid
          else {
            props.parentCallback(subQuestionId, true, event.target.value, "", false, subQuestion.answerRequired);
          }
        }
      } else {
        props.parentCallback(subQuestionId, false, "", "", true, subQuestion.answerRequired);
      }
    } else {
      props.parentCallback(subQuestionId, "", event.target.value, "", true, subQuestion.answerRequired);
    }
  };

  // Handle File Upload Inputs
  const handleFileUpload = (subQuestionId) => (event) => {
    if (event.target.files.length > fileUploadLimit || uploadedFiles.length >= fileUploadLimit) {
      setFileUploadSnack(true);
    } else {
      saveFile(event.target.files, subQuestionId);
    }
  };

  const handleFileRemove = async (file) => {
    setFileUploadProgress(true);
    let fileName = file.fileName;
    setFiles(uploadedFiles.filter((item) => item.fileName !== fileName));
    deleteFile(fileName);
  };

  const saveFile = (files, subQuestionId) => {
    const fileArray = Object.values(files);
    setFileUploadProgress(true);
    fileArray.map(async (file) => {
      setFileUploadProgress(true);
      let fileName = file.name;
      let fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
      try {
        const requestURL =
          AppConfig.baseUrl +
          "partners/" +
          getUserEmail() +
          "/" +
          AppConfig.fileUpload +
          "?encodeValue=" +
          getEncodeValue() +
          "&fileExtension=" +
          fileExtension +
          "&fileName=" +
          fileName;
        file
          .stream()
          .getReader()
          .read()
          .then(({ done, value }) => {
            sendRequest(requestURL, "POST", file, AppConfig.fileUpload);
          });
      } catch (error) {
        setFileUploadProgress(false);
        setSnackbarData({
          message: error,
          open: true,
          severity: "error",
          onClose: onCloseSnackbar,
        });
        return error;
      }
    });
  };

  const deleteFile = (fileName) => {
    if (fileName) {
      const requestURL =
        AppConfig.baseUrl +
        "partners/" +
        getUserEmail() +
        "/" +
        AppConfig.fileDelete +
        "/" +
        fileName +
        "?encodeValue=" +
        getEncodeValue();
      sendRequest(requestURL, "DELETE", null, AppConfig.deleteFile);
    }
  };

  const [fieldError, setFieldError] = useState("");
  const [booleanError, setBooleanError] = useState("");
  const [isFilesValid, setIsFilesValid] = useState(true);

  useEffect(() => {
    const validationMessage = validateField();
    setFieldError(validationMessage);
  }, [descAnswer, props.isSubmitted, props.isDrafted, subQuestion.answerRequired]);

  useEffect(() => {
    const errorMessage = validateBoolean();
    setBooleanError(errorMessage);
  }, [boolAnswer, props.isSubmitted, subQuestion.answerRequired]);

  useEffect(() => {
    const isValid = validateFiles();
    setIsFilesValid(isValid);
  }, [props.isSubmitted]);

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.fileUpload:
        if (data) {
          setFileUploadProgress(false);
          const obj = {
            fileName: data.data.fileName,
            fileType: data.data.fileType,
            subQuestionId: props.subQuestionId,
            questionId: props.questionId,
          };
          props.handleSubmitFiles(obj);
        } else if (error) {
          setFileUploadProgress(false);
          setFileUploadContentSnack(true);
          console.error("Error: File Upload Failed");
        }
        break;
      case AppConfig.deleteFile:
        if (data) {
          var fileName = data.data.fileName;
          setFiles(uploadedFiles.filter((item) => item.fileName !== fileName));
          props.handleDeleteFiles(fileName);
          setFileUploadProgress(false);
        } else if (error) {
          setFileUploadProgress(false);
          console.error("Error: File Deletion Failed");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    if (subQuestion.answerFileUpload) {
      if (uploadedFiles.length > fileUploadLimit) {
        setFileUploadSnack(true);
      }
    }
  }, [uploadedFiles, subQuestion.answerFileUpload, subQuestion.subQuestionId]);

  useEffect(() => {}, [props.encodeValue]);

  // Validation Function to show Errors in the Form UI
  const validateField = () => {
    // If form is submitted and inputs are empty
    if (props.isSubmitted && descAnswer.trim() === "" && subQuestion.answerRequired) {
      return "Required";
    }

    // If category 5 is empty when drafting
    else if (props.isDrafted && descAnswer.trim() === "" && category.categoryId === 5) {
      return "Required";
    }

    // When field is empty and not yet submitted
    else if (!props.isSubmitted && descAnswer.trim() === "") {
      return "";
    }

    // When field is empty and not yet drafted
    else if (!props.isDrafted && descAnswer.trim() === "") {
      return "";
    }
    // There's an input entered
    else {
      // There's a regex to be matched
      if (subQuestion.validationRegex !== "") {
        // If regex is matching with the input
        const regex = RegExp(subQuestion.validationRegex);

        // If form is a drafted form and returning no error
        if (props.answer && props.answer[0] && props.answer[0].descriptionAnswer && !fieldChanged) {
          return "";
        } else {
          if (regex.test(descAnswer)) {
            return "";
          }
          // If regex is not matching with the input
          else {
            // Returning error message to be displayed
            let ret = "Invalid Input";
            if (question.description !== "") {
              ret = "Please enter a valid " + question.questionDescription;
            }
            if (subQuestion.description !== "") {
              ret = "Please enter a valid " + subQuestion.description;
            }
            return ret;
          }
        }
      }
      // There's no regex to be matched
      else {
        return "";
      }
    }
  };

  // Validate Function to returning errors for parent Component
  const validateField2 = (answer) => {
    // When the form initializes, all the fields are valid
    if (!props.isSubmitted && answer === false) {
      return "";
    }
    // If input is Empty after clicking on the field
    else if (answer === "" && subQuestion.answerRequired) {
      return "Required";
    }
    // If form is submitted and inputs are empty
    else if (props.isSubmitted && answer === false) {
      return "Required";
    }
    // There's an input entered
    else {
      // There's a regex to be matched
      if (subQuestion.validationRegex !== "") {
        // If regex is matching with the input
        const regex = RegExp(subQuestion.validationRegex);
        if (regex.test(answer)) {
          return "";
        }
        // If regex is not matching with the input
        else {
          // Returning error message to be displayed
          let ret = "Invalid Input";
          if (question.description !== "") {
            ret = "Please enter a valid " + question.questionDescription;
          }
          if (subQuestion.description !== "") {
            ret = "Please enter a valid " + subQuestion.description;
          }
          return ret;
        }
      }
      // There's no regex to be matched
      else {
        return "";
      }
    }
  };

  const validateFiles = () => {
    if (props.isSubmitted && uploadedFiles.length === 0 && subQuestion.answerRequired) {
      return false;
    } else {
      return true;
    }
  };

  // Validation Function for Boolean answers
  const validateBoolean = () => {
    if (props.isSubmitted && boolAnswer === undefined && subQuestion.answerRequired) {
      return "Required";
    } else {
      return "";
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFileUploadSnack(false);
  };

  const closeSnack = () => {
    setFileUploadContentSnack(false);
  };

  const onCloseSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const subQuestionTextField = (
    <TextField
      name={subQuestion.subQuestionId + ""}
      multiline
      minRows={3}
      value={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
      size="small"
      onChange={handleFormChange(subQuestion.subQuestionId)}
      label={subQuestion.description}
      autoComplete="nope"
      error={fieldError !== ""}
      helperText={fieldError}
      variant="outlined"
      disabled={props.formCompleted}
      fullWidth
    />
  );

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={fileUploadSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          You can only upload upto {fileUploadLimit} files
        </Alert>
      </Snackbar>
      <Snackbar open={fileUploadContentSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={closeSnack} severity="error">
          Invalid file format. Please try again.
        </Alert>
      </Snackbar>
      <CustomSnackbar snackbar={snackbarData} />

      {/* Render Boolean Input */}
      {question.questionId === subQuestion.questionId && subQuestion.answerType && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <FormControl component="fieldset" disabled={props.formCompleted} error={booleanError !== ""}>
                <RadioGroup
                  row
                  id={subQuestion.subQuestionId}
                  onChange={handleRadioChange(subQuestion.subQuestionId)}
                  // value={props.answer && props.answer[0] && props.answer[0].booleanAnswer==="1" ? "yes" : props.answer && props.answer[0] && props.answer[0].booleanAnswer==="0" ? "no" :"" }>
                  value={
                    props?.answer[0] && props.answer[0]?.booleanAnswer
                      ? "yes"
                      : props.answer && props.answer[0] && !props.answer[0].booleanAnswer
                      ? "no"
                      : ""
                  }
                >
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
                <FormHelperText>{booleanError}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </Grid>
      )}

      {/* Render Description Input for Boolean Input Yes */}
      {question.questionId === subQuestion.questionId &&
        subQuestion.answerDescription &&
        subQuestion.answerType &&
        boolAnswer && (
          <Grid item xs={12}>
            <div className={classes.questionParent}>
              <div className={classes.questionIndex}></div>
              <div className={classes.questionDetails}>
                <TextField
                  name={subQuestion.subQuestionId + ""}
                  value={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                  size="small"
                  minRows={3}
                  multiline
                  onChange={handleFormChange(subQuestion.subQuestionId)}
                  label={subQuestion.description}
                  autoComplete="nope"
                  error={fieldError !== ""}
                  helperText={fieldError}
                  variant="outlined"
                  disabled={props.formCompleted}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
        )}

      {/* Render Description Input Only */}
      {question.questionId === subQuestion.questionId &&
        subQuestion.answerDescription &&
        !subQuestion.answerType &&
        subQuestion.subQuestionId !== 10 &&
        subQuestion.subQuestionId !== 11 && (
          <Grid item xs={12}>
            <div className={classes.questionParent}>
              <div className={classes.questionIndex}></div>
              <div className={classes.questionDetails}>
                {props.status === FORM_STATUS.TR_EDIT_REQUESTED ||
                props.status === FORM_STATUS.FULL_EDIT_REQUESTED ||
                props.status === FORM_STATUS.TR_DRAFTED ? (
                  <Tooltip
                    title={
                      subQuestion.subQuestionId === TR_SUB_QUESTION_IDS.TR1_COMPANY_NAME ||
                      subQuestion.subQuestionId === TR_SUB_QUESTION_IDS.TR2_COMPANY_NAME
                        ? "If you edit the company name, the Trade Reference form will be re-sent."
                        : subQuestion.subQuestionId === TR_SUB_QUESTION_IDS.TR1_EMAIL ||
                          subQuestion.subQuestionId === TR_SUB_QUESTION_IDS.TR2_EMAIL
                        ? "If you edit the email, the Trade Reference form will be re-sent."
                        : ""
                    }
                    arrow
                  >
                    {React.cloneElement(subQuestionTextField, {
                      disabled:
                        question.questionId !== CATEGORY3_QUESTION_IDS[1] &&
                        question.questionId !== CATEGORY3_QUESTION_IDS[2] &&
                        question.categoryId !== SIGNATORY_CATEGORY_ID
                          ? props.formCompleted
                          : props.formCompleted && !props.TrEditRequested,
                    })}
                  </Tooltip>
                ) : (
                  subQuestionTextField
                )}
              </div>
            </div>
          </Grid>
        )}

      {category.categoryId == 3 && subQuestion.description === "E-mail" && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Typography variant="body2" className={classes.emailMsg}>
                The Trade reference form will be sent to the relevant email id once you complete and submit this form
              </Typography>
            </div>
          </div>
        </Grid>
      )}

      {/* Render select currency dropdown */}
      {category.categoryId == 3 && subQuestion.subQuestionId === 10 && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Grid container spacing={2}>
                {subQuestion.description !== "" && (
                  <Grid item xs={12} sm={6} lg={2} className={classes.verticalcenter}>
                    <Typography>{subQuestion.description}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={5} lg={3}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    size="small"
                    variant="outlined"
                    name={subQuestion.subQuestionId + ""}
                    label={subQuestion.description}
                    error={fieldError !== ""}
                    helperText={fieldError}
                    disabled={props.formCompleted}
                  >
                    <Select
                      native
                      name="country"
                      value={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                      onChange={handleFormChange(subQuestion.subQuestionId)}
                      autoWidth
                    >
                      <option value=""></option>
                      {CurrencyList &&
                        CurrencyList.currencies &&
                        CurrencyList.currencies.length > 0 &&
                        CurrencyList.currencies.map((currency) => {
                          return (
                            <option key={currency} value={currency}>
                              {currency}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}

      {/* Render select value entering  Scale*/}
      {category.categoryId == 3 && subQuestion.subQuestionId === 11 && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Grid container spacing={2}>
                {subQuestion.description !== "" && (
                  <Grid item xs={12} sm={6} lg={2} className={classes.verticalcenter}>
                    <Typography>{subQuestion.description}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={5} lg={3}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    size="small"
                    variant="outlined"
                    // error={!validateField("country")}
                    name={subQuestion.subQuestionId + ""}
                    label={subQuestion.description}
                    error={fieldError !== ""}
                    helperText={fieldError}
                    disabled={props.formCompleted}
                  >
                    <Select
                      native
                      name="country"
                      value={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                      onChange={handleFormChange(subQuestion.subQuestionId)}
                      autoWidth
                    >
                      <option value=""></option>
                      {CurrencyList &&
                        CurrencyList.scales &&
                        CurrencyList.scales.length > 0 &&
                        CurrencyList.scales.map((scale) => {
                          return (
                            <option key={scale} value={scale}>
                              {scale}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              {!loading && (
                <CreditChecklist
                  formCompleted={props.formCompleted}
                  isSubmitted={props.isSubmitted}
                  creditCheckListCallback={props.creditCheckListCallback}
                  scale={scale}
                />
              )}
            </div>
          </div>
        </Grid>
      )}

      {/* Render File upload Input */}
      {question.questionId === subQuestion.questionId && subQuestion.answerFileUpload && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Grid container spacing={2}>
                {subQuestion.description !== "" && (
                  <Grid item xs={12} sm={2} lg={1} className={classes.verticalcenter}>
                    <Typography>{subQuestion.description}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={10} lg={11}>
                  <MuiThemeProvider theme={filePickerTheme}>
                    <Button
                      className={classes.uploadButton}
                      size="small"
                      variant="outlined"
                      component="label"
                      color={isFilesValid ? "primary" : "secondary"}
                      disabled={props.formCompleted}
                    >
                      <input
                        type="file"
                        onChange={handleFileUpload(subQuestion.subQuestionId)}
                        id={subQuestion.subQuestionId + ""}
                        size="large"
                        multiple
                        hidden
                        required
                      />
                      Choose Files
                    </Button>
                  </MuiThemeProvider>
                  <React.Fragment>
                    {uploadedFiles.map((file, key) => {
                      return (
                        <React.Fragment key={key}>
                          {props.formCompleted && (
                            <>
                              <Chip
                                className={classes.fileChip}
                                label={
                                  file.fileName && file.fileName.length > 25
                                    ? file.fileName.substring(24, file.fileName.length)
                                    : "file"
                                }
                                color="secondary"
                                variant="outlined"
                              ></Chip>
                            </>
                          )}

                          {/* If form is not completed delete option available */}
                          {!props.formCompleted && (
                            <Chip
                              className={classes.fileChip}
                              label={
                                file.fileName && file.fileName.length > 25
                                  ? file.fileName.substring(24, file.fileName.length)
                                  : "file"
                              }
                              color="secondary"
                              variant="outlined"
                              onDelete={() => handleFileRemove(file)}
                              deleteIcon={<CloseIcon />}
                            ></Chip>
                          )}

                          {key === uploadedFiles.length - 1 && fileUploadProgress && (
                            <LinearProgress className={classes.linearProgress} color="secondary" />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default SubQuestionDetails;
