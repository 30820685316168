import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AppConfig, CountryList, VALIDATION } from "../../../configs/client-config";
import { applyLengthValidation } from "../../../utils/validate";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 10,
  },
  accordion: {},
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  accordionDetails: {
    padding: "2% 4%",
    paddingBottom: 30,
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  headingIndex2: {
    flexBasis: "13.5%",
    "@media(max-width: 1280px)": {
      flexBasis: "4%",
    },
    "@media(max-width: 960px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName2: {
    flexBasis: "88%",
    textAlign: "justify",
    "@media(max-width: 1280px)": {
      flexBasis: "96%",
    },
    "@media(max-width: 960px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
}));

const newForm = {
  companyName: "",
  taxIdNumber: "",
  country: "",
  state: "",
  cityProvince: "",
  streetAddress: "",
  postalZipCode: "",
  yearOfIncorporation: "",
  noOfEmployees: "",
  noOfCustomers: "",
  websiteLink: "",
  formCompleted: "",
};

const CompanyInformation = (props) => {
  const classes = useStyles();
  let formStruct = {};
  const [expanded, setExpanded] = useState(true);
  const [formData, setFormData] = useState(newForm);
  const [companyInfo, setCompanyInfo] = useState(newForm);
  const tempData = formStruct;

  const accordionHandleChange = (newExpanded) => {
    setExpanded(!expanded);
  };

  const handleFormChange = (length) => (e) => {
    var tempObj = formData;
    var value = e.target.value;

    if (length) {
      value = applyLengthValidation(value, length);
    }
    tempObj[e.target.name] = value;
    props.setCompanyInfo(tempObj);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (e) => {
    e.persist();
    var tempObj = formData;
    if (e.target.name === "country") {
      tempObj["state"] = "";
      props.setCompanyInfo(tempObj);
      setFormData((prevState) => ({
        ...prevState,
        state: "",
      }));
    }
    tempObj = formData;
    tempObj[e.target.name] = e.target.value;
    props.setCompanyInfo(tempObj);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validateField = (field) => {
    // validating fields when submitting
    if (formData[field] === "" && props.isSubmitted) return false;
    // validating form when drafting
    else if (formData["companyName"] === "" && field === "companyName" && props.isDrafted) return false;
    else if (formData["streetAddress"] === "" && field === "streetAddress" && props.isDrafted) return false;
    else if (formData["taxIdNumber"] === "" && field === "taxIdNumber" && props.isDrafted) return false;
    else if (formData["cityProvince"] === "" && field === "cityProvince" && props.isDrafted) return false;
    else if (formData["postalZipCode"] === "" && field === "postalZipCode" && props.isDrafted) return false;
    else if (formData["country"] === "" && field === "country" && props.isDrafted) return false;
    // no validation error
    else return true;
  };

  const validateState = (field) => {
    if (formData[field] === "" && formData["country"] === "United States") return false;
    if (formData[field] === "" && formData["country"] === "Canada") return false;
    else return true;
  };

  const validateYear = (field) => {
    //if form is not yet submitted
    if (formData[field] === "" && !props.isSubmitted) {
      return "";
    }
    // if year is empty when submitting
    else if (formData[field] === "" && props.isSubmitted) {
      return "Required";
    }
    // checking for email address validity
    else {
      if (/^\d{4}$/.test(formData[field])) {
        return "";
      } else {
        return "Please enter a valid Year";
      }
    }
  };
  const validateNumber = (field) => {
    //if form is not yet submitted
    if (formData[field] === "" && !props.isSubmitted) {
      return "";
    }
    // if year is empty when submitting
    else if (formData[field] === "" && props.isSubmitted) {
      return "Required";
    }
    // checking for email address validity
    else {
      if (/^\d+$/.test(formData[field])) {
        return "";
      } else {
        return "Please enter a Number";
      }
    }
  };
  const validateURL = (field) => {
    //if form is not yet submitted
    if (formData[field] === "" && !props.isSubmitted) {
      return "";
    }
    // if year is empty when submitting
    else if (formData[field] === "" && props.isSubmitted) {
      return "Required";
    }
    // checking for email address validity
    else {
      if (/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(formData[field])) {
        return "";
      } else {
        return "Please enter a valid URL";
      }
    }
  };

  const getCountriesList = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.countries;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });

      let data = await response.json();

      if (response.status === 200 && data.length > 0) {
        CountryList.countries = data.map((country) => country.countryName);
      } else {
        console.warn("No countries retrieved");
      }
    } catch (error) {
      console.error("Failed to fetch countries: ", error);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    if (props.companyInfo) {
      setFormData(props.companyInfo);
      let data = props.companyInfo;
      // If drafted form is opened
      var tempInfo = {
        companyName: data?.companyName ? data.companyName : "",
        taxIdNumber: data?.taxIdNumber ? data.taxIdNumber : "",
        country: data?.country ? data.country : "",
        state: data?.state ? data.state : "",
        cityProvince: data?.cityProvince ? data.cityProvince : "",
        streetAddress: data?.streetAddress ? data.streetAddress : "",
        postalZipCode: data?.postalZipCode ? data.postalZipCode : "",
        yearOfIncorporation: data?.yearOfIncorporation ? data.yearOfIncorporation : "",
        noOfEmployees: data?.noOfEmployees ? data.noOfEmployees : "",
        noOfCustomers: data?.noOfCustomers ? data.noOfCustomers : "",
        websiteLink: data?.websiteLink ? data.websiteLink : "",
        formCompleted: "",
      };
      // setCompanyInfo(tempInfo);
    }
  }, [props.companyInfo]);

  useEffect(() => {
    const validateField2 = (field) => {
      if (formData[field] === "") return false;
      else return true;
    };
    const validateState = (field) => {
      if (formData[field] === "" && formData["country"] === "United States") return false;
      if (formData[field] === "" && formData["country"] === "Canada") return false;
      else return true;
    };
    const validateYear = (field) => {
      //if field is empty
      if (formData[field] === "") {
        return false;
      }
      // checking for email address validity
      else {
        if (/^\d{4}$/.test(formData[field])) {
          return true;
        } else {
          return false;
        }
      }
    };

    const validateNumber = (field) => {
      //if field is empty
      if (formData[field] === "") {
        return false;
      }
      // checking for email address validity
      else {
        if (/^\d+$/.test(formData[field])) {
          return true;
        } else {
          return false;
        }
      }
    };

    const validateURL = (field) => {
      //if field is empty
      if (formData[field] === "") {
        return false;
      }
      // checking for email address validity
      else {
        if (
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(formData[field])
        ) {
          return true;
        } else {
          return false;
        }
      }
    };

    // validating the Company Information and return true/false
    const validateForm = () => {
      if (
        validateField2("companyName") &&
        validateField2("taxIdNumber") &&
        validateField2("streetAddress") &&
        validateState("state") &&
        validateField2("cityProvince") &&
        validateField2("postalZipCode") &&
        validateField2("country") &&
        validateYear("yearOfIncorporation") &&
        validateNumber("noOfEmployees") &&
        validateNumber("noOfCustomers") &&
        validateURL("websiteLink")
      ) {
        return true;
      } else {
        return false;
      }
    };

    // callback the form data and validation state
    if (validateForm()) {
      props.callback(formData, true);
    } else {
      props.callback(formData, false);
    }
  }, [formData]);

  return (
    <Paper className={classes.paper}>
      <Accordion className={classes.accordion} expanded={expanded} onChange={accordionHandleChange}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}>
              <b>1.</b>
            </Typography>
            <Typography className={classes.headingName}>
              <b>Company Information</b>
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.1</Typography>
              <Typography className={classes.headingName}>
                Please provide Candidate’s full business name, aliases, and other names
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="companyName"
                className={classes.headingName}
                // Was default value
                value={formData.companyName ? formData.companyName : ""}
                onChange={handleFormChange(VALIDATION.LENGTH_150)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={!validateField("companyName")}
                helperText={!validateField("companyName") ? "Required" : null}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12} >
                        </Grid> */}
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.2</Typography>
              <Typography className={classes.headingName}>Tax ID Number</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="taxIdNumber"
                // Was default value
                value={formData.taxIdNumber ? formData.taxIdNumber : ""}
                onChange={handleFormChange(VALIDATION.LENGTH_50)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={!validateField("taxIdNumber")}
                helperText={!validateField("taxIdNumber") ? "Required" : null}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.3</Typography>
              <Typography className={classes.headingName}>Country</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <FormControl
                className={classes.formControl}
                fullWidth
                size="small"
                variant="outlined"
                error={!validateField("country")}
                disabled={props.formCompleted}
              >
                <Select
                  native
                  name="country"
                  size="small"
                  value={formData.country}
                  onChange={handleSelectChange}
                  autoWidth
                >
                  <option value=""></option>
                  {CountryList &&
                    CountryList.countries &&
                    CountryList.countries.length > 0 &&
                    CountryList.countries.map((country) => {
                      return (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      );
                    })}
                </Select>
                <FormHelperText>{!validateField("country") ? "Required" : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.4</Typography>
              <Typography className={classes.headingName}>State</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <FormControl
                className={classes.formControl}
                fullWidth
                size="small"
                variant="outlined"
                error={
                  (formData.country === "United States" || formData.country === "Canada") && !validateState("state")
                }
                disabled={props.formCompleted}
              >
                {(formData.country === "United States" || formData.country === "Canada") && (
                  <Select
                    disabled={props.formCompleted}
                    native
                    name="state"
                    size="small"
                    value={formData.state}
                    onChange={handleSelectChange}
                    autoWidth
                  >
                    <option value=""></option>
                    {formData.country === "United States" &&
                      CountryList.US_States &&
                      CountryList.US_States.length > 0 &&
                      CountryList.US_States.map((state) => {
                        return (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        );
                      })}
                    {formData.country === "Canada" &&
                      CountryList.Canada_States &&
                      CountryList.Canada_States.length > 0 &&
                      CountryList.Canada_States.map((state) => {
                        return (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </Select>
                )}
                {formData.country !== "United States" && formData.country !== "Canada" && (
                  <TextField
                    name="state"
                    value={formData.state || ""}
                    onChange={handleFormChange(VALIDATION.LENGTH_50)}
                    size="small"
                    variant="outlined"
                    autoComplete="nope"
                    disabled={props.formCompleted}
                    fullWidth
                  />
                )}
                <FormHelperText>
                  {(formData.country === "United States" || formData.country === "Canada") && !validateState("state")
                    ? "Required"
                    : null}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.5</Typography>
              <Typography className={classes.headingName}>City/Province</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="cityProvince"
                // Was default value
                value={formData.cityProvince ? formData.cityProvince : ""}
                onChange={handleFormChange(VALIDATION.LENGTH_50)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={!validateField("cityProvince")}
                helperText={!validateField("cityProvince") ? "Required" : null}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.6</Typography>
              <Typography className={classes.headingName}>Street Address</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="streetAddress"
                // Was default value
                value={formData.streetAddress ? formData.streetAddress : ""}
                onChange={handleFormChange(VALIDATION.LENGTH_100)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={!validateField("streetAddress")}
                helperText={!validateField("streetAddress") ? "Required" : null}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.7</Typography>
              <Typography className={classes.headingName}>Postal/Zip Code</Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="postalZipCode"
                // Was default value
                value={formData.postalZipCode ? formData.postalZipCode : ""}
                onChange={handleFormChange(VALIDATION.LENGTH_50)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={!validateField("postalZipCode")}
                helperText={!validateField("postalZipCode") ? "Required" : null}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.verticalcenter}>
              <Typography variant="body2">
                The information provided below will remain strictly confidential and used for the creditworthiness
                assessment only.
              </Typography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}>1.8</Typography>
                  <Typography className={classes.headingName2}>Year of incorporation</Typography>
                </Grid>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}></Typography>
                  <TextField
                    name="yearOfIncorporation"
                    // Was default value
                    value={formData.yearOfIncorporation ? formData.yearOfIncorporation : ""}
                    onChange={handleFormChange(VALIDATION.LENGTH_10)}
                    size="small"
                    variant="outlined"
                    autoComplete="nope"
                    error={validateYear("yearOfIncorporation") !== ""}
                    helperText={validateYear("yearOfIncorporation")}
                    disabled={props.formCompleted}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}>1.9</Typography>
                  <Typography className={classes.headingName2}>No of current employees</Typography>
                </Grid>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}></Typography>
                  <TextField
                    name="noOfEmployees"
                    // Was default value
                    value={formData.noOfEmployees ? formData.noOfEmployees : ""}
                    onChange={handleFormChange(VALIDATION.LENGTH_10)}
                    size="small"
                    variant="outlined"
                    autoComplete="nope"
                    error={validateNumber("noOfEmployees") !== ""}
                    helperText={validateNumber("noOfEmployees")}
                    disabled={props.formCompleted}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}>1.10</Typography>
                  <Typography className={classes.headingName2}>No of current customers</Typography>
                </Grid>
                <Grid item xs={12} className={classes.headingParent}>
                  <Typography className={classes.headingIndex2}></Typography>
                  <TextField
                    name="noOfCustomers"
                    // Was default value
                    value={formData.noOfCustomers ? formData.noOfCustomers : ""}
                    onChange={handleFormChange(VALIDATION.LENGTH_10)}
                    size="small"
                    variant="outlined"
                    autoComplete="nope"
                    error={validateNumber("noOfCustomers") !== ""}
                    helperText={validateNumber("noOfCustomers")}
                    disabled={props.formCompleted}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}>1.11</Typography>
              <Typography className={classes.headingName}>
                Please provide any company brochures or website address for Candidate that explains its business and
                experience.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.headingParent}>
              <Typography className={classes.headingIndex}></Typography>
              <TextField
                name="websiteLink"
                // Was default value
                value={formData.websiteLink ? formData.websiteLink : ""}
                type="url"
                onChange={handleFormChange(VALIDATION.LENGTH_250)}
                size="small"
                variant="outlined"
                autoComplete="nope"
                error={validateURL("websiteLink") !== ""}
                helperText={validateURL("websiteLink")}
                disabled={props.formCompleted}
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default CompanyInformation;
