import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AppConfig } from "../../../configs/client-config";
import { getEncodeValue, getUserEmail } from "../../../utils/utils";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
  div1: {
    marginBottom: 30,
  },
  border: {
    marginTop: 20,
    border: "1px solid #ddd",
  },
  boldText: {
    fontWeight: 500,
  },
  tableHeading: {
    backgroundColor: "#F5F5F5",
  },
  cell1: {
    width: "70%",
    textAlign: "right",
  },
  btnContainer: {
    marginTop: 10,
  },
  submitItem: {
    textAlign: "end",
  },
  btn: {
    textTransform: "none",
  },
  tableTitle: {
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 15,
  },
}));

const CreditChecklist = (props) => {
  const classes = useStyles();
  const [year1, setYear1] = useState({
    year: 1,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [year2, setYear2] = useState({
    year: 2,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [year3, setYear3] = useState({
    year: 3,
    currentAssets: "",
    currentLiability: "",
    cash: "",
    investments: "",
    totalDebt: "",
    totalAssets: "",
    revenue: "",
    profit: "",
  });
  const [loading, setLoading] = useState(true);

  const getCreditScoreItems = async () => {
    const requestURL =
      AppConfig.baseUrl +
      AppConfig.getCreditScoreItems +
      "?encodeValue=" +
      getEncodeValue() +
      "&email=" +
      getUserEmail();
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.items && body.items.length > 0) {
          let year1Data =
            body.items.filter((data) => data.year === 1).length > 0
              ? body.items.filter((data) => data.year === 1)[0]
              : {
                  year: 1,
                  currentAssets: "",
                  currentLiability: "",
                  cash: "",
                  investments: "",
                  totalDebt: "",
                  totalAssets: "",
                  revenue: "",
                  profit: "",
                };
          let year2Data =
            body.items.filter((data) => data.year === 2).length > 0
              ? body.items.filter((data) => data.year === 2)[0]
              : {
                  year: 2,
                  currentAssets: "",
                  currentLiability: "",
                  cash: "",
                  investments: "",
                  totalDebt: "",
                  totalAssets: "",
                  revenue: "",
                  profit: "",
                };
          let year3Data =
            body.items.filter((data) => data.year === 3).length > 0
              ? body.items.filter((data) => data.year === 3)[0]
              : {
                  year: 3,
                  currentAssets: "",
                  currentLiability: "",
                  cash: "",
                  investments: "",
                  totalDebt: "",
                  totalAssets: "",
                  revenue: "",
                  profit: "",
                };
          setYear1(mapToScale(year1Data));
          setYear2(mapToScale(year2Data));
          setYear3(mapToScale(year3Data));
          props.creditCheckListCallback(mapToScale(year1Data), mapToScale(year2Data), mapToScale(year3Data));
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      setLoading(false);
      return null;
    } catch (error) {
      setLoading(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    getCreditScoreItems();
  }, []);

  useEffect(() => {}, [props.scale]);

  const mapToScale = (obj) => {
    const object = JSON.parse(JSON.stringify(obj));
    for (var key in object) {
      if (object[key] === "" || object[key] === null || props.scale === 0) {
        object[key] = 0;
      }
      if (key !== "year" && props.scale > 0 && object[key]) {
        object[key] = object[key] / props.scale;
      }
    }
    return object;
  };

  function getScaleName() {
    let scale = props.scale;
    if (scale === "Actual") {
      return "(Actual)";
    } else if (scale === "Thousands (000)") {
      return "(000)";
    } else if (scale === "Millions (000,000)") {
      return "(000,000)";
    } else if (scale === "Billions (000,000,000)") {
      return "(000,000,000)";
    }
  }

  useEffect(() => {
    let scaleValue = 1;
    let scale = props.scale;
    if (scale === "Actual") {
      scaleValue = 1;
    } else if (scale === "Thousands (000)") {
      scaleValue = 1000;
    } else if (scale === "Millions (000,000)") {
      scaleValue = 1000000;
    } else if (scale === "Billions (000,000,000)") {
      scaleValue = 1000000000;
    }
    props.creditCheckListCallback(year1, year2, year3);
  }, [props.scale]);

  const handleChange = (year, category) => (e) => {
    let year1Data = JSON.parse(JSON.stringify(year1));
    let year2Data = JSON.parse(JSON.stringify(year2));
    let year3Data = JSON.parse(JSON.stringify(year3));
    if (year === 1) {
      year1Data[category] = e.target.value;
      setYear1(year1Data);
    }
    if (year === 2) {
      year2Data[category] = e.target.value;
      setYear2(year2Data);
    }
    if (year === 3) {
      year3Data[category] = e.target.value;
      setYear3(year3Data);
    }
    props.creditCheckListCallback(year1Data, year2Data, year3Data);
  };

  function returnCell(year, category) {
    return (
      <TableCell align="right">
        {
          !loading &&
            year === 1 &&
            (props.formCompleted ? (
              <span>{year1[category] ? parseFloat(year1[category]) : ""}</span>
            ) : (
              <TextField
                type="number"
                size="small"
                className={classes.cell1}
                onChange={handleChange(year, category)}
                error={props.isSubmitted && (year1[category] === "" || year1[category] == "0")}
                inputProps={{ min: 0, style: { textAlign: "end" } }}
                value={year1[category] ? year1[category] : ""}
              />
            ))
          // Was default value
        }
        {
          !loading &&
            year === 2 &&
            (props.formCompleted ? (
              <span>{year2[category] ? parseFloat(year2[category]) : ""}</span>
            ) : (
              <TextField
                type="number"
                size="small"
                className={classes.cell1}
                onChange={handleChange(year, category)}
                error={props.isSubmitted && (year2[category] === "" || year2[category] == "0")}
                inputProps={{ min: 0, style: { textAlign: "end" } }}
                value={year2[category] ? year2[category] : ""}
              />
            ))
          // Was default value
        }
        {
          !loading &&
            year === 3 &&
            (props.formCompleted ? (
              <span>{year3[category] ? parseFloat(year3[category]) : ""}</span>
            ) : (
              <TextField
                type="number"
                size="small"
                className={classes.cell1}
                onChange={handleChange(year, category)}
                error={props.isSubmitted && (year3[category] === "" || year3[category] == "0")}
                inputProps={{ min: 0, style: { textAlign: "end" } }}
                value={year3[category] ? year3[category] : ""}
              />
            ))
          // Was default value
        }
      </TableCell>
    );
  }

  return (
    <React.Fragment>
      <div>
        <TableContainer className={classes.border}>
          <Table size="small">
            <TableHead className={classes.tableHeading}>
              <TableCell>Items</TableCell>
              <TableCell align="right">Year 1 {getScaleName()}</TableCell>
              <TableCell align="right">Year 2 {getScaleName()}</TableCell>
              <TableCell align="right">Year 3 {getScaleName()}</TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Current Assets</TableCell>
                {returnCell(1, "currentAssets")}
                {returnCell(2, "currentAssets")}
                {returnCell(3, "currentAssets")}
              </TableRow>
              <TableRow>
                <TableCell>Current Liability</TableCell>
                {returnCell(1, "currentLiability")}
                {returnCell(2, "currentLiability")}
                {returnCell(3, "currentLiability")}
              </TableRow>
              <TableRow>
                <TableCell>Cash</TableCell>
                {returnCell(1, "cash")}
                {returnCell(2, "cash")}
                {returnCell(3, "cash")}
              </TableRow>
              <TableRow>
                <TableCell>Investments</TableCell>
                {returnCell(1, "investments")}
                {returnCell(2, "investments")}
                {returnCell(3, "investments")}
              </TableRow>
              <TableRow>
                <TableCell>Total Debt</TableCell>
                {returnCell(1, "totalDebt")}
                {returnCell(2, "totalDebt")}
                {returnCell(3, "totalDebt")}
              </TableRow>
              <TableRow>
                <TableCell>Total Assets</TableCell>
                {returnCell(1, "totalAssets")}
                {returnCell(2, "totalAssets")}
                {returnCell(3, "totalAssets")}
              </TableRow>
              <TableRow>
                <TableCell>Revenue</TableCell>
                {returnCell(1, "revenue")}
                {returnCell(2, "revenue")}
                {returnCell(3, "revenue")}
              </TableRow>
              <TableRow>
                <TableCell>Profit</TableCell>
                {returnCell(1, "profit")}
                {returnCell(2, "profit")}
                {returnCell(3, "profit")}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default CreditChecklist;
