import React, { useEffect, useState, memo } from "react";
import { Page, Text, View, Image, Document, StyleSheet, Font } from "@react-pdf/renderer";
import Roboto_Light from "../../resources/fonts/Roboto/Roboto-Light.ttf";
import Roboto_Bold from "../../resources/fonts/Roboto/Roboto-Bold.ttf";

// Create styles
// const useStyles = makeStyles((theme: Theme) => createStyles({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: '#E4E4E4'
//       },
//       section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1
//       }
//   }));

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto_Light }, // font-style: normal, font-weight: normal
    { src: Roboto_Bold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  root: {
    fontFamily: "Roboto",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  body: {
    fontFamily: "Roboto",
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 50,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  body1: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
  },
  smallHeader: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    // marginBottom: 10,
  },
  justifyText: {
    textAlign: "justify",
    fontSize: 12,
    marginTop: 10,
  },
  marginBottom40: {
    marginBottom: 40,
  },
  section: {
    margin: 5,
    padding: 5,
  },
  column: {
    float: "left",
    width: "50%",
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  left: {
    width: "50%",
  },
  right: {
    width: "50%",
  },
  image: {
    height: "auto",
    width: 150,
  },
  logo: {
    padding: 5,
    width: 150,
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  question: {
    textAlign: "justify",
    fontSize: 12,
    marginBottom: 5,
  },
  answer: {
    fontSize: 11,
    marginBottom: 10,
  },
});

const LegalQuestions = (props) => {
  useEffect(() => {}, [props.legalQA]);

  return (
    // <Page size="A4" style={styles.body}>
    <>
      <Text style={styles.smallHeader}>Legal Information</Text>
      <View style={styles.section}>
        <View style={styles.body1}>
          {/* <View style={styles.section}> */}
          {props?.legalQA &&
            Object.values(props.legalQA).map((e) => (
              <>
                <Text style={styles.question}>
                  {e?.question?.questionDescription ? e.question.questionDescription : " N/A"}
                </Text>
                <Text style={styles.answer}>
                  {e?.subQuestion?.answerType
                    ? e?.answer?.booleanAnswer !== undefined
                      ? e?.answer?.booleanAnswer
                        ? e.answer?.descriptionAnswer
                          ? e.answer.descriptionAnswer
                          : "Yes"
                        : " No"
                      : " No"
                    : e?.subQuestion?.answerDescription
                    ? e?.answer?.descriptionAnswer !== undefined
                      ? " " + e.answer.descriptionAnswer
                      : " N/A"
                    : e?.subQuestion?.answerTypeFileUpload
                    ? e?.answer?.fileCount > 0
                      ? " Yes"
                      : "No"
                    : " N/A"}
                </Text>
              </>
            ))}
          {/* </View> */}
        </View>
      </View>
    </>
    // </Page>
  );
};

const SignatoryDetails = ({ nameAndDesignation }) => (
  <View style={styles.section}>
    <View style={styles.right}>
      <Text style={styles.question}>.....................................................</Text>
      <Text style={styles.question}>Name: {nameAndDesignation?.name ? " " + nameAndDesignation.name : " N/A"}</Text>
      <Text style={styles.question}>
        Designation: {nameAndDesignation?.designation ? " " + nameAndDesignation.designation : " N/A"}
      </Text>
      <Text style={styles.question}>Email: {nameAndDesignation?.email ? " " + nameAndDesignation.email : " N/A"}</Text>
    </View>
  </View>
);

// Create Document Component
const PDFCreator = memo(
  (props) => {
    const [answers, setAnswers] = useState({});
    const [nameAndDesignation, setNameAndDesignationAnswers] = useState({
      name: "",
      designation: "",
      email: "",
    });

    useEffect(() => {
      setAnswers(props.answers);
      if (
        props.nameAndDesignation &&
        props.nameAndDesignation["Name"] !== undefined &&
        props.nameAndDesignation["Designation"] !== undefined &&
        props.nameAndDesignation["E-mail"] !== undefined
      ) {
        var tempObject = {
          name: props.nameAndDesignation["Name"],
          designation: props.nameAndDesignation["Designation"],
          email: props.nameAndDesignation["E-mail"],
        };
        setNameAndDesignationAnswers(tempObject);
      }
    }, [props.answers, props.nameAndDesignation, props.legalQA]);

    return (
      <Document>
        <Page size="A4" style={styles.body}>
          <Image
            style={styles.logo}
            src={"https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"}
          />

          <Text style={styles.title}>WSO2 Due Diligence Checklist</Text>
          <Text style={styles.body1}>
            Thank you for considering to be a part of the WSO2 partner program. Below you will find the information you
            entered to register your Company as a Reseller.
          </Text>
          <Text style={styles.smallHeader}>Company Information</Text>
          <View style={styles.section}>
            <View style={styles.body1}>
              <Text style={styles.question}>
                Company name:
                {/* </Text> */}
                {/* <Text style={styles.answer}> */}
                {answers?.companyName ? " " + answers.companyName : " N/A"}
              </Text>
              <Text style={styles.question}>
                City/Province: {answers?.cityProvince ? " " + answers.cityProvince : " N/A"}
              </Text>
              <Text style={styles.question}>
                Postal/Zip Code:
                {answers?.postalZipCode ? " " + answers.postalZipCode : " N/A"}
              </Text>
              <Text style={styles.question}>
                Year of incorporation:
                {answers?.yearOfIncorporation ? " " + answers.yearOfIncorporation : " N/A"}
              </Text>
              <Text style={styles.question}>
                No of current customers: {answers?.noOfCustomers ? " " + answers.noOfCustomers : " N/A"}
              </Text>
              <Text style={styles.question}>
                No of current employees: {answers?.noOfEmployees ? " " + answers.noOfEmployees : " N/A"}
              </Text>
              <Text style={styles.question}>
                Website Link: {answers?.websiteLink ? " " + answers.websiteLink : " N/A"}
              </Text>
              <Text style={styles.question}>
                Tax ID Number: {answers?.taxIdNumber ? " " + answers.taxIdNumber : " N/A"}
              </Text>
              <Text style={styles.question}>Country: {answers?.country ? " " + answers.country : " N/A"}</Text>
              <Text style={styles.question}>State: {answers?.state ? " " + answers.state : " N/A"}</Text>
              <Text style={styles.question}>
                Street Address: {answers?.streetAddress ? " " + answers.streetAddress : " N/A"}
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.body}>
          <LegalQuestions legalQA={props.legalQA} />
          {props.cpi > 50 && <SignatoryDetails nameAndDesignation={nameAndDesignation} />}
        </Page>
        {props.cpi <= 50 && (
          <Page size="A4" style={styles.body}>
            <Text style={styles.smallHeader}>Compliance Form </Text>
            <View>
              <Text style={styles.justifyText}>
                I, the undersigned, on behalf of myself and my company (collectively and individually,
                “Representative”), hereby certify, represent, warrant, and covenant the following to WSO2 and its
                subsidiaries (collectively, the “Company”):
              </Text>
              <Text style={styles.justifyText}>
                1. Representative (i) has reviewed a copy of the Company’s Partner Compliance Guide (the “Policy”); (ii)
                has been given the opportunity to ask the Company any questions about the Policy; and (iii) understands
                its obligations under the Policy.
              </Text>
              <Text style={styles.justifyText}>
                2. In the performance of services for the Company, Representative (i) has and shall comply with the
                Policy and all applicable laws and regulations, and (ii) has not and shall not cause the Company or its
                affiliates to be in violation of the U.S. Foreign Corrupt Practices Act of 1977 as amended (the “FCPA”),
                the U.S. Travel Act, the U.S. Domestic Bribery Statute, the UK Bribery Act 2010 or any other applicable
                law or regulation, including, but not limited to, those addressing corruption, bribery, kickbacks,
                embezzlement, ethical business conduct, fraud, money laundering, sanctions, or export controls
                (collectively, “Relevant Laws”).
              </Text>
              <Text style={styles.justifyText}>
                3. Representative shall not and has not, directly or indirectly through third parties, corruptly paid,
                promised or offered to pay, or authorized the payment of, any money or given any promise or offer to
                give, or authorized the giving of anything of value, to a Government Official (as defined below) or any
                other individual, entity, or organization, for any improper purpose including, but not limited to,
                obtaining, retaining, or directing licenses, permits, other regulatory approvals or inspections, court
                decisions, sales, or business.
              </Text>
              <Text style={styles.justifyText}>
                4. Representative understands that “Government Official” means (i) an official, director, officer,
                employee, agent or representative, regardless of rank, of any government, military, or government-owned
                or affiliated entity or organization (including representatives of sovereign wealth funds or
                doctors/researchers who work for government-affiliated hospitals, universities, or research
                institutions); (ii) any person acting in an official capacity for or on behalf of any government or
                military; (iii) any candidate for political office, any political party or any official of a political
                party; (iv) any officer, employee, agent or representative of any public international organization such
                as the United Nations, the World Health Organization, or the World Bank; or (v) any member of a royal
                family.
              </Text>
              <Text style={styles.justifyText}>
                5. Representative has not and shall not directly or indirectly offer, provide, solicit, or accept any
                improper, corrupt, or illegal payment, gratuity, emolument, bribe, kickback or other benefit, favor, or
                advantage to or from any Government Official or any other individual, entity, or organization,
                including, but not limited to, the Company’s personnel or other vendors/contractors.
              </Text>
              <Text style={styles.justifyText}>
                6. Representative has not been accused of, or investigated or prosecuted for violating any Relevant Laws
                or similar laws or regulations.
              </Text>
              <Text style={[styles.justifyText, styles.marginBottom40]}>
                7. Neither Representative, nor to its knowledge after due inquiry, any of Representative’s owners,
                directors, officers, employees, agents, representatives, or business partners or any immediate family
                member (sibling, spouse, child, or parent) of the foregoing is a Government Official. No Government
                Official has any direct or indirect interest in the revenues or profits of Representative’s business
                activities, other than for lawful tax purposes.
              </Text>
            </View>
            <SignatoryDetails nameAndDesignation={nameAndDesignation} />
          </Page>
        )}
      </Document>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.answers) === JSON.stringify(nextProps.answers) || prevProps.cpi === nextProps.cpi;
  }
);

// ReactPDF.render(<PDFViewer />);
export default PDFCreator;
