import React, { Fragment } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AppConfig } from "../../configs/admin-config";
import Header from "../../components/Header";
import LandingPage from "../../components/LandingPage";
import NoAccessPage from "../../components/NoAccessPage";
import NotFoundPage from "../../components/NotFoundPage";
import PartnerPendingPage from "../../components/admin-app/Resellers/ResellerDashboard/PendingPage";
import TradeReferencePendingPage from "../../components/admin-app/TradeReferences/TradeReferenceDashboard/PendingPage2";
import PartnerDashboard from "../../components/admin-app/Resellers/ResellerDashboard/ResellerDashboard";
import TradeReferenceDashboard from "../../components/admin-app/TradeReferences/TradeReferenceDashboard/TradeReferenceDashboard";
import ViewPDF from "../../components/admin-app/Resellers/ResellerDashboard/Finance/ViewPDF";
import ViewImage from "../../components/admin-app/Resellers/ResellerDashboard/Finance/ViewImage";

const theme = createTheme({
  palette: {
    primary: {
      light: "#d7e2de",
      main: "#465867",
      dark: "#212a32",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffc808",
      main: "#ff7300",
      contrastText: "#fff",
    },
    text: {
      white: "#ffffff",
    },
  },
});

const Admin = (props) => {
  return (
    <div className="adminApp">
      <MuiThemeProvider theme={theme}>
        <Router>
          {props.isAuthorised ? (
            <Fragment>
              <Header handleLogout={props.handleLogout} />
              <Switch>
                <Route
                  exact
                  path={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + ":tabName"}
                  render={({ match, location, history }) => {
                    var isValid =
                      AppConfig.tabs.findIndex((tab) => {
                        return tab === match.params.tabName;
                      }) >= 0
                        ? true
                        : false;

                    return (
                      <React.Fragment>
                        {isValid ? (
                          <span>
                            <LandingPage match={match} />
                          </span>
                        ) : (
                          <NotFoundPage />
                        )}
                      </React.Fragment>
                    );
                  }}
                />
                <Route
                  exact
                  path={AppConfig.urls.ADMIN + AppConfig.urls.PENDING_PAGE + ":id"}
                  component={PartnerPendingPage}
                ></Route>
                <Route
                  exact
                  path={AppConfig.urls.ADMIN + AppConfig.urls.RESELLER_DASHBOARD + ":id/:tabName"}
                  component={PartnerDashboard}
                ></Route>
                <Route
                  exact
                  path={AppConfig.urls.ADMIN + AppConfig.urls.PENDING_PAGE_2 + ":linkId"}
                  component={TradeReferencePendingPage}
                ></Route>
                <Route
                  exact
                  path={AppConfig.urls.ADMIN + AppConfig.urls.TRADE_REFERENCE_FORM + ":companyId/:linkId"}
                  component={TradeReferenceDashboard}
                ></Route>
                <Route exact path={AppConfig.urls.ADMIN + AppConfig.urls.VIEW_PDF} component={ViewPDF}></Route>
                <Route exact path={AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE} component={ViewImage}></Route>
                <Route path={AppConfig.urls.ADMIN + AppConfig.urls.NOT_FOUND_PAGE} component={NotFoundPage} />
                <Redirect
                  exact
                  from={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE}
                  to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + AppConfig.defaultTabName}
                />
                <Redirect exact from={AppConfig.urls.ADMIN} to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE} />
                <Redirect exact from={"/"} to={AppConfig.urls.ADMIN} />
                <Redirect
                  exact
                  from={AppConfig.urls.ADMIN + AppConfig.urls.NO_ACCESS_PAGE}
                  to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + AppConfig.defaultTabName}
                />
                <Route path="*" exact={true} component={NotFoundPage} />
              </Switch>
            </Fragment>
          ) : props.isAuthorised === false ? (
            <div>
              <Redirect to={AppConfig.urls.ADMIN + AppConfig.urls.NO_ACCESS_PAGE} />
              <Route path={AppConfig.urls.ADMIN + AppConfig.urls.NO_ACCESS_PAGE} component={NoAccessPage} />
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </Router>
      </MuiThemeProvider>
    </div>
  );
};

export default Admin;
