import { useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import SubQuestionDetails from "./SubQuestionDetails";
import { SIGNATORY_CATEGORY_ID } from "../../../configs/client-config";

var parse = require("html-react-parser");

const useStyles = makeStyles((theme) => ({
  descriptionParent: {
    display: "flex",
  },

  descriptionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  descriptionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "95%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400p6x)": {
      flexBasis: "85%",
    },
  },
}));

const QuestionDetails = (props) => {
  const classes = useStyles();
  const question = props.question;
  const subQuestions = props.subQuestions;
  const category = props.category;
  const files = props.files;

  const callback = (subQuestionId, booleanAnswer, descAnswer, uploadFiles, isValid, answerRequired) => {
    props.parentCallback(
      subQuestionId,
      question.questionId,
      Boolean(booleanAnswer),
      descAnswer,
      uploadFiles,
      isValid,
      answerRequired
    );
  };

  useEffect(() => {}, [props.encodeValue, props.answers, props.files]);

  return (
    <Fragment>
      {category.categoryId === question.categoryId && (
        <Fragment>
          <Grid item xs={12}>
            <div className={classes.descriptionParent}>
              <Typography className={classes.descriptionIndex}></Typography>
              <Typography variant="body2" className={classes.descriptionDetails}>
                {parse(question.questionHeading)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.verticalcenter}>
            <div className={classes.descriptionParent}>
              <Typography className={classes.descriptionIndex}>
                {category.categoryId === SIGNATORY_CATEGORY_ID ? 6 : category.categoryId}.{props.index}
              </Typography>

              {/* Legal info main yes/no questions */}
              <Typography className={classes.descriptionDetails}>{parse(question.questionDescription)}</Typography>
            </div>
          </Grid>
          {subQuestions.length > 0 &&
            subQuestions.map((subQuestion) => {
              return subQuestion.questionId === question.questionId ? (
                <SubQuestionDetails
                  key={subQuestion.subQuestionId}
                  encodeValue={props.encodeValue}
                  category={category}
                  question={question}
                  subQuestion={subQuestion}
                  files={files}
                  parentCallback={callback}
                  formCompleted={props.formCompleted}
                  TrEditRequested={props.TrEditRequested}
                  isSubmitted={props.isSubmitted}
                  isDrafted={props.isDrafted}
                  // companyId={props.companyId}
                  creditCheckListCallback={props.creditCheckListCallback}
                  creditScaleCallback={props.creditScaleCallback}
                  questionId={subQuestion.questionId}
                  subQuestionId={subQuestion.subQuestionId}
                  answer={
                    props.answers &&
                    props.answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId)
                  }
                  handleSubmitFiles={props.handleSubmitFiles}
                  handleDeleteFiles={props.handleDeleteFiles}
                  status={props.status}
                />
              ) : null;
            })}
        </Fragment>
      )}
    </Fragment>
  );
};

export default QuestionDetails;
